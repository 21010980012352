import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@material-ui/core/Button';

const LoginButton = () => {
    const { isLoading, isAuthenticated, user, loginWithRedirect, logout } = useAuth0();

    if (isLoading) {
        return '';
    }

    if (isAuthenticated) {
        return (<Button color="secondary" onClick={() => logout({ returnTo: window.location.origin })}>{user.name}</Button>);
    } else {
        return <Button variant="contained" onClick={() => loginWithRedirect()}>Log In</Button>;
    }
};

export default LoginButton;
