import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { AgreementEx } from '../../models/Agreement';
import { Receiver } from '../../models/Receiver';
import { Sponsor } from '../../models/Sponsor';
import { SponsorListAgreement, SponsorListItem } from '../../models/SponsorListItem';
import { SponsorType } from '../../models/SponsorType';
import SponsorItem from './SponsorItem';

function createListItem(sponsor: Sponsor, agreements: AgreementEx[], sponsorTypes: SponsorType[], receivers: Receiver[]): SponsorListItem {
    const result = {
        id: sponsor.id,
        name: sponsor.name,
        agreements: agreements.filter(a => a.sponsorId === sponsor.id && a.isActive).map(a => createAgreement(a, sponsorTypes, receivers)),
    };

    return result;
}

function createAgreement(agreement: AgreementEx, sponsorTypes: SponsorType[], receivers: Receiver[]): SponsorListAgreement {
    const type = sponsorTypes.find(st => st.id === agreement.sponsorTypeId);
    const receiver = receivers.find(r => r.id === agreement.receiverId);
    return {
        id: agreement.id,
        type: type?.name,
        typeId: type?.id,
        receiver: receiver?.name,
        receiverId: receiver?.id,
    };
}

export type SponsorsListProps = {
    sponsors: Sponsor[];
    agreements: AgreementEx[];
    sponsorTypes: SponsorType[];
    receivers: Receiver[];
};

export default function SponsorsList({ sponsors, agreements, sponsorTypes, receivers }: SponsorsListProps) {
    const listData = sponsors.map(s => createListItem(s, agreements, sponsorTypes, receivers));
    listData.sort((a, b) => a.name.localeCompare(b.name));

    return (
        <TableContainer component={Paper}>
            <Table aria-label="Sponsor table">
                <TableHead>
                    <TableRow>
                        <TableCell>Fadder</TableCell>
                        <TableCell>Avtale</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {listData.map(s => (
                        <SponsorItem key={s.id} sponsor={s}></SponsorItem>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
