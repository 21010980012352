import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import startOfDay from 'date-fns/startOfDay';
import { TokenGetter } from '../hooks/TokenGetter';
import { Agreement, AgreementEx } from '../models/Agreement';
import { fetchGet, fetchPost, fetchPut } from './api';

function isActive(agreement: Agreement) {
    const today = startOfDay(new Date());

    if (agreement.from) {
        const from = parseISO(agreement.from);
        if (isBefore(today, from)) {
            return false;
        }
    }

    if (agreement.to) {
        const to = parseISO(agreement.to);
        if (isAfter(today, to)) {
            return false;
        }
    }

    return true;
}

export const agreementsAll = async (tokenGetter: TokenGetter): Promise<AgreementEx[]> => {
    const responseData = await fetchGet<Agreement[]>('agreements', tokenGetter);
    const result = responseData.map(r => ({ ...r, isActive: isActive(r) }));
    return result;
};

export const agreementById = async (id: string, tokenGetter: TokenGetter): Promise<AgreementEx> => {
    const responseData = await fetchGet<Agreement>(`agreements/${id}`, tokenGetter);
    const result = { ...responseData, isActive: isActive(responseData) };
    return result;
};

export const agreementsAdd = async (tokenGetter: TokenGetter, agreement: Agreement) => {
    return await fetchPost('agreements', agreement, tokenGetter);
};

export const agreementUpdate = async (tokenGetter: TokenGetter, agreement: Agreement) => {
    return await fetchPut('agreements', agreement, tokenGetter);
};
