import { DropzoneArea } from 'material-ui-dropzone';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { bankTransactionsAtom } from '../../atoms/atoms';
import parseFile from './helpers/file-parser';

export default function FileImport() {
    const setBankTransactions = useSetRecoilState(bankTransactionsAtom);

    function handleChange(files: File[]) {
        files.forEach(file => {
            const reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = () => {
                const content = parseFile(reader.result as string);
                setBankTransactions(content);
            };
            reader.readAsText(file, 'ISO-8859-1');
        });
    }
    return (
        <div>
            <DropzoneArea acceptedFiles={['text/plain']} filesLimit={1} onChange={handleChange} />
        </div>
    );
}
