import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import formatISO from 'date-fns/formatISO';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { vippsTransactionsAtom } from '../../atoms/atoms';

export default function FileContent() {
    const transactions = useRecoilValue(vippsTransactionsAtom);

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Oppgjørs Id</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Salg</TableCell>
                        <TableCell>Gebyr</TableCell>
                        <TableCell>Utbetalt</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactions.map((t, index) => (
                        <TableRow key={index}>
                            <TableCell>{t.settlementId}</TableCell>
                            <TableCell>{formatISO(t.date, { representation: 'date' })}</TableCell>
                            <TableCell>{t.grossAmount}</TableCell>
                            <TableCell>{t.fee}</TableCell>
                            <TableCell>{t.netAmount}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
