import Grid from '@material-ui/core/Grid';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { filterAtom } from '../../atoms/atoms';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import useSponsorsContext from '../../dataContexts/useSponsorsContext';
import { AgreementEx } from '../../models/Agreement';
import { Sponsor } from '../../models/Sponsor';
import { SponsorFilterModel } from '../../models/SponsorFilterModel';
import { includeSponsor } from '../utils/sponsor-utils';
import SponsorActions from './SponsorActions';
import SponsorFilter from './SponsorFilter';
import SponsorsList from './SponsorsList';

function sortSponsors(sponsors: Sponsor[]) {
    if (sponsors == null) {
        return [];
    }

    const result = [...sponsors];
    result.sort((a, b) => a.name.localeCompare(b.name));
    return result;
}

function filterByText(sponsors: Sponsor[], filter:SponsorFilterModel) {
    if (filter.text !== '') {
        const regexp = new RegExp(filter.text, 'i');
        return sponsors.filter(s => includeSponsor(s, regexp));
    }

    return sponsors;
}

function filterByActiveAgreement(sponsors: Sponsor[], agreements: AgreementEx[], filter: SponsorFilterModel) {
    if (filter.activeAgreement) {
        return sponsors.filter(s => sponsorHasActiveAgreement(s, agreements));
    }

    return sponsors;
}

function sponsorHasActiveAgreement(sponsor: Sponsor, agreements: AgreementEx[]) {
    const sponsorAgreements = agreements.filter(a => a.sponsorId === sponsor.id);
    return sponsorAgreements.length > 0 && sponsorAgreements.some(a => a.isActive);
}

function filterByMissingEmail(sponsors: Sponsor[], filter: SponsorFilterModel) {
    if (filter.missingEmail) {
        return sponsors.filter(s => s.email1 == null || s.email1 === '');
    }

    return sponsors;
}

function filterByMissingCustNo(sponsors: Sponsor[], filter: SponsorFilterModel) {
    if (filter.missingCustNo) {
        return sponsors.filter(s => s.custNo == null || s.custNo === '');
    }

    return sponsors;
}

function filterSponsors(sponsors: Sponsor[], agreements: AgreementEx[], filter: SponsorFilterModel) {
    if (sponsors?.length > 0 && agreements?.length > 0) {
        let result = filterByMissingCustNo(sponsors, filter);
        result = filterByMissingEmail(result, filter);
        result = filterByText(result, filter);
        result = filterByActiveAgreement(result, agreements, filter);
        return result;
    }

    return [];
}

export default function Sponsors() {
    const { sponsors, agreements, receivers, sponsorTypes, isLoading, error } = useSponsorsContext();
    const sorted = useMemo(() => sortSponsors(sponsors), [sponsors]);
    const filter = useRecoilValue(filterAtom);
    const sponsorsToView = useMemo(() => filterSponsors(sorted, agreements, filter), [sorted, agreements, filter]);

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <Error error={error} />;
    }

    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <SponsorActions sponsors={sponsorsToView} agreements={agreements} sponsorTypes={sponsorTypes} receivers={receivers} />
            </Grid>
            <Grid item>
                <SponsorFilter />
            </Grid>
            <Grid item>
                <SponsorsList sponsors={sponsorsToView} agreements={agreements} sponsorTypes={sponsorTypes} receivers={receivers} />
            </Grid>
        </Grid>
    );
}
