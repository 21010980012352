import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import formatISO from 'date-fns/formatISO';
import isValid from 'date-fns/isValid';
import React from 'react';
import { SponsorTransaction } from '../../models/SponsorTransaction';

export type FileContentProps = {
    transactions: SponsorTransaction[];
    showSponsor?: boolean;
};

export default function FileContent({ transactions, showSponsor }: FileContentProps) {
    if (transactions == null) {
        return <>Loading</>;
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Account</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Text</TableCell>
                        <TableCell>In</TableCell>
                        {showSponsor && <TableCell>Sponsor</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactions.map((t, index) => (
                        <TableRow key={index}>
                            <TableCell>{t.accountNumber}</TableCell>
                            <TableCell>{isValid(t.date) ? formatISO(t.date, { representation: 'date' }) : ''}</TableCell>
                            <TableCell>{t.text}</TableCell>
                            <TableCell>{t.in}</TableCell>
                            {showSponsor && <TableCell>{t.sponsor?.name}</TableCell>}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
