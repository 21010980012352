import { format } from 'date-fns';
import FileSaver from 'file-saver';
import * as XMLWriter from 'xml-writer';
import { includeInRf1301 } from '../features/utils/sponsor-utils';
import { SponsorGift } from '../models/SponsorGift';

export default function useAltinn() {
    function downloadRf1301(sponsorGifts: SponsorGift[]) {
        const xml = createXml(sponsorGifts);
        saveFile(xml);
    }

    function saveFile(content: string) {
        const blob = new Blob([content], { type: 'text/json' });

        const dt = format(new Date(), "yyyyMMdd'_'HHmmss");

        const filename = `Altinn ${dt}.xml`;
        FileSaver.saveAs(blob, filename);
    }

    function createXml(sponorGifts: SponsorGift[]): string {
        const entritesToInclude = sponorGifts.filter(e => includeInRf1301(e));

        const xw = new XMLWriter(true);
        xw.startDocument('1.0', 'UTF-8');
        xw.startElement('melding');
        xw.writeAttribute('xmlns', 'urn:ske:fastsetting:innsamling:gavefrivilligorganisasjon:v2');
        xw.writeAttribute('xmlns:xsi', 'http://www.w3.org/2001/XMLSchema-instance');
        xw.writeAttribute(
            'xsi:schemaLocation',
            'urn:ske:fastsetting:innsamling:gavefrivilligorganisasjon:v2 gavefrivilligorganisasjon_v2_0.xsd'
        );

        xw.startElement('leveranse');
        xw.writeElement('kildesystem', 'Kildesystemet v2.0.5');

        addOppgaveGiver(xw);

        xw.writeElement('inntektsaar', 2023);
        xw.writeElement('oppgavegiversLeveranseReferanse', 'gc_2023_1');
        xw.writeElement('leveransetype', 'ordinaer');

        addReportEntries(xw, entritesToInclude);
        addSummary(xw, entritesToInclude);

        xw.endElement();
        xw.endElement();
        xw.endDocument();

        return xw.toString();
    }

    function addOppgaveGiver(xw: any): void {
        xw.startElement('oppgavegiver');
        xw.writeElement('organisasjonsnummer', '912646998');
        xw.writeElement('organisasjonsnavn', 'Gambia Care');
        xw.startElement('kontaktinformasjon');
        xw.writeElement('navn', 'Henning Truslew Gulliksen');
        xw.writeElement('telefonnummer', '90967588');
        xw.writeElement('varselEpostadresse', 'henning@truslew.no');
        xw.writeElement('varselSmsMobilnummer', '90967588');
        xw.endElement();
        xw.endElement();
    }

    function addReportEntries(xw: any, entries: SponsorGift[]) {
        for (const e of entries) {
            if (e.sponsor.birthNumber != null) {
                addPrivate(xw, e);
            } else if (e.sponsor.organizationNumber != null) {
                addCompany(xw, e);
            }
        }
    }

    function addPrivate(xw: any, entry: SponsorGift): void {
        xw.startElement('oppgave');

        xw.startElement('oppgaveeier');
        xw.writeElement('foedselsnummer', entry.sponsor.birthNumber);
        xw.writeElement('navn', entry.sponsor.name);
        xw.endElement();

        xw.writeElement('beloep', entry.sum);

        xw.endElement();
    }

    function addCompany(xw: any, entry: SponsorGift): void {
        xw.startElement('oppgave');

        xw.startElement('oppgaveeier');
        xw.writeElement('organisasjonsnummer', entry.sponsor.organizationNumber);
        xw.writeElement('navn', entry.sponsor.name);
        xw.endElement();

        xw.writeElement('beloep', entry.sum);

        xw.endElement();
    }

    function addSummary(xw: any, entries: SponsorGift[]): void {
        const count = entries.length;
        const sum = entries.reduce((p, c) => p + c.sum, 0);
        xw.startElement('oppgaveoppsummering');
        xw.writeElement('antallOppgaver', count);
        xw.writeElement('sumBeloep', sum);
        xw.endElement();
    }

    return { downloadRf1301 };
}
