import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { showDrawer } from '../../atoms/atoms';
import { items } from './MenuItems';

function MenuItem({ item }) {
    return (
        <ListItem button component={Link} key={item.link} to={item.link}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
        </ListItem>
    );
}

export default function SideMenu() {
    const [isOpen, setIsOpen] = useRecoilState(showDrawer);

    const handleCloseDrawer = event => {
        setIsOpen(false);
    };

    return (
        <div onClick={handleCloseDrawer} onKeyDown={handleCloseDrawer}>
            <Drawer open={isOpen} onClose={handleCloseDrawer}>
                {items.map(i => (
                    <MenuItem key={i.link} item={i}></MenuItem>
                ))}
            </Drawer>
        </div>
    );
}
