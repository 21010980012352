import { useMemo } from 'react';
import generate from '../features/utils/id';
import { mergeQueryStatuses } from '../features/utils/query-utils';
import useAgreements from '../hooks/useAgreements';
import useGifts from '../hooks/useGifts';
import useSponsors from '../hooks/useSponsors';
import { AgreementEx } from '../models/Agreement';
import { Gift } from '../models/Gift';
import { Sponsor } from '../models/Sponsor';
import { SponsorGift } from '../models/SponsorGift';

export default function useGiftsListContext(year: number) {
    const sponsorQuery = useSponsors();
    const agreementsQuery = useAgreements();
    const giftQuery = useGifts(year);

    const sponsors = useMemo(() => sortSponsors(sponsorQuery.data), [sponsorQuery.data]);
    const giftsMap = useMemo(() => createGiftMap(giftQuery.data), [giftQuery.data]);
    const sponsorsGifts = useMemo(() => createSponsosrGifts(sponsors, agreementsQuery.data, giftsMap), [
        sponsors,
        agreementsQuery.data,
        giftsMap,
    ]);

    const merge = mergeQueryStatuses([sponsorQuery, agreementsQuery, giftQuery]);

    const result = {
        sponsorsGifts,
        isIdle: merge.isIdle,
        isLoading: merge.isLoading,
        isFetching: merge.isFetching,
        error: merge.error,
    };

    return result;
}

function createSponsosrGifts(sponsors: Sponsor[], agreements?: AgreementEx[], giftsMap?: Map<string, Gift>) {
    if (sponsors != null && agreements != null && giftsMap != null) {
        return sponsors.map(s => createSponsorGift(s, agreements, giftsMap.get(s.id)));
    }

    return [];
}

function createSponsorGift(sponsor: Sponsor, agreements: AgreementEx[], gift?: Gift): SponsorGift {
    const result = {
        sponsor,
        giftId: gift?.id ?? generate(),
        gift: gift?.gift ?? 0,
        lunch: gift?.lunch ?? 0,
        adjust: gift?.adjust ?? 0,
        loss: gift?.loss ?? 0,
        sum: 0,
        haveData: false,
        isLunchPartner: agreements.some(a => a.sponsorId === sponsor.id && a.sponsorTypeId === '6dyrvgpu8g'),
    };

    result.sum = result.gift + result.lunch + result.adjust - result.loss;
    result.haveData = result.gift !== 0 || result.lunch !== 0 || result.adjust !== 0 || result.loss !== 0;

    return result;
}

function createGiftMap(gifts?: Gift[]): Map<string, Gift> {
    if (gifts == null) {
        return new Map<string, Gift>();
    }
    return new Map<string, Gift>(gifts.map(g => [g.sponsorId, g]));
}

function sortSponsors(sponsors?: Sponsor[]) {
    if (sponsors == null) {
        return [];
    }

    const result = [...sponsors];
    result.sort((a, b) => a.name.localeCompare(b.name));
    return result;
}
