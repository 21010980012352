import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { bankAccountsAll } from '../backend/bankAccountsApi';

function useBankAccounts() {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    return useQuery(
        'bankAccounts',
        async () => {
            return await bankAccountsAll(getAccessTokenSilently);
        },
        { staleTime: Infinity, cacheTime: Infinity, enabled: isAuthenticated }
    );
}

export default useBankAccounts;
