import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { Link } from 'react-router-dom';

export default function YearliAgreementItem({ item }) {
    const { sponsor, agreement, sponsorType, receiver } = item;
    return (
        <TableRow>
            <TableCell>
                <Link to={`/sponsors/${sponsor?.id}`}>{sponsor?.name}</Link>
            </TableCell>
            <TableCell>
                <Link to={`/sponsor-types/${sponsorType?.id}`}>{sponsorType?.name}</Link>
            </TableCell>
            <TableCell>{receiver && <Link to={`/receivers/${receiver.id}`}>{receiver.name}</Link>}</TableCell>
            <TableCell>{agreement.hasVariableAmount ? 'Variable' : agreement.amount}</TableCell>
            <TableCell>{agreement.month}</TableCell>
        </TableRow>
    );
}
