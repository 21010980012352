import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    table: {
        minWidth: 100,
        maxWidth: 600
    },
    amount: {
        width: 100
    }
});

function SponsorCell({ item }) {
    return (
        <TableCell>
            <Link to={`/sponsors/${item.sponsorId}`}>{item.sponsor}</Link>
        </TableCell>
    );
}

function TypeCell({ item }) {
    return (
        <TableCell>
            <Link to={`/sponsor-types/${item.typeId}`}>{item.type}</Link>
        </TableCell>
    );
}

function ReceiverCell({ item }) {
    return (
        <TableCell>
            <Link to={`/receivers/${item.receiverId}`}>{item.receiver}</Link>
        </TableCell>
    );
}

function AmountCell({ amount }) {
    const classes = useStyles();
    return (
        <TableCell align="right" className={classes.amount}>
            {amount}
        </TableCell>
    );
}

function MonthlyCell({ item }) {
    return <AmountCell amount={item.monthly} />;
}

function YearlyCell({ item }) {
    return <AmountCell amount={item.yearly} />;
}

export default function SponsorGrid({ sponsors }) {
    const classes = useStyles();

    const showType = sponsors?.some(s => s.type);
    const showReceiver = sponsors?.some(s => s.receiver);
    const showMonthly = sponsors?.some(s => s.monthly > 0);
    const showYearly = sponsors?.some(s => s.yearly > 0);

    return (
        <TableContainer>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Fadder</TableCell>
                        {showType && <TableCell>Type</TableCell>}
                        {showReceiver && <TableCell>Mottaker</TableCell>}
                        {showMonthly && <TableCell>Månedlig</TableCell>}
                        {showYearly && <TableCell>Årlig</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sponsors.map(agreement => (
                        <TableRow key={agreement.id}>
                            <SponsorCell item={agreement} />
                            {showType && <TypeCell item={agreement} />}
                            {showReceiver && <ReceiverCell item={agreement} />}
                            {showMonthly && <MonthlyCell item={agreement} />}
                            {showYearly && <YearlyCell item={agreement} />}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
