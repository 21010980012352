import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { queryCache, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { agreementUpdate } from '../../backend/agreementsApi';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import useEditAgreementContext from '../../dataContexts/useEditAgreementContext';
import AgreementForm from './AgreementForm';

const save = async (sponsor, agreement, mutate) => {
    try {
        await mutate(agreement);
        toast.success(`"${sponsor.name}" Saved`);
        queryCache.invalidateQueries(['agreements'], {
            refetchActive: true,
            refetchInactive: false,
        });
    } catch (error) {
        toast.error(`Error saving agreement for "${sponsor.name}"`, { autoClose: false });
    }
};

export default function AgreementEdit() {
    const { getAccessTokenSilently } = useAuth0();
    let { agreementId } = useParams();
    let { sponsor, agreement, receivers, sponsorTypes, isFetching, error } = useEditAgreementContext(agreementId);

    const [mutate] = useMutation(formData => agreementUpdate(getAccessTokenSilently, formData), { throwOnError: true });

    if (isFetching) {
        return <Loading />;
    }

    if (error) {
        return <Error error={error} />;
    }

    return (
        <AgreementForm
            sponsor={sponsor}
            agreement={agreement}
            receivers={receivers}
            sponsorTypes={sponsorTypes}
            save={agreement => save(sponsor, agreement, mutate)}
        ></AgreementForm>
    );
}
