import AddIcon from '@material-ui/icons/Add';
import Assignment from '@material-ui/icons/Assignment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PeopleIcon from '@material-ui/icons/People';
import React from 'react';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

export const items = [
    {
        label: 'Faddere',
        link: '/sponsors',
        icon: <PeopleIcon />,
        main: true,
    },
    {
        label: 'Motttakere',
        link: '/receivers',
        icon: <ChildCareIcon />,
        main: true,
    },
    {
        label: 'Fadder Typer',
        link: '/sponsor-types',
        icon: <Assignment />,
        main: true,
    },
    {
        label: 'Ny Fadder',
        link: '/sponsors/new',
        icon: <PersonAddIcon />,
        main: true,
    },
    {
        label: 'Årlige avtaler',
        link: '/yearly',
        icon: <CalendarTodayIcon />,
        main: false,
    },
    {
        label: 'Bank Avstemming',
        link: '/bank-reconsiliation',
        main: false,
    },
    {
        label: 'Vipps Avstemming',
        link: '/vipps-reconsiliation',
        main: false,
    },
    {
        label: 'Årsoppgave Skatteetaten',
        link: '/tax-settlement/list',
        main: false,
    },
    {
        label: 'Kunde Balanse Import',
        link: '/tax-settlement/import',
        main: false,
    },
    {
        label: 'Import',
        link: '/import',
        icon: <AddIcon />,
        main: false,
    },
    {
        label: 'Logg ut',
        link: '/logout',
        icon: <ExitToAppIcon />,
        main: false,
    },
];
