import { mergeQueryStatuses } from '../features/utils/query-utils';
import useAgreements from '../hooks/useAgreements';
import useReceivers from '../hooks/useReceivers';
import useSponsors from '../hooks/useSponsors';
import useSponsorsTypes from '../hooks/useSponsorTypes';

function useSponsorsContext() {
    const sponsors = useSponsors();
    const agreements = useAgreements();
    const receivers = useReceivers();
    const sponsorTypes = useSponsorsTypes();

    const merge = mergeQueryStatuses([sponsors, agreements, receivers, sponsorTypes]);

    const result = {
        sponsors: sponsors.data ?? [],
        agreements: agreements.data ?? [],
        receivers: receivers.data ?? [],
        sponsorTypes: sponsorTypes.data ?? [],
        isIdle: merge.isIdle,
        isLoading: merge.isLoading,
        error: merge.error,
    };

    return result;
}

export default useSponsorsContext;
