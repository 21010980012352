import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { Link } from 'react-router-dom';
import { SponsorListAgreement, SponsorListItem } from '../../models/SponsorListItem';

function Spacer() {
    return <span>&ensp;&ndash;&ensp;</span>;
}

export type AgreementTextProps = {
    agreement: SponsorListAgreement;
    first: boolean;
};

function AgreementText({ agreement, first }: AgreementTextProps) {
    return (
        <>
            {!first && <Spacer />}
            <Link to={`/sponsor-types/${agreement.typeId}`}>{agreement.type}</Link>
            {agreement.receiverId && (
                <>
                    <Link to={`/receivers/${agreement.receiverId}`}> ({agreement.receiver})</Link>
                </>
            )}
        </>
    );
}

export type SponsorItemProps = {
    sponsor: SponsorListItem;
};

export default function SponsorItem({ sponsor }: SponsorItemProps) {
    return (
        <TableRow key={sponsor.id}>
            <TableCell>
                <Link to={`/sponsors/${sponsor.id}`}>{sponsor.name}</Link>
            </TableCell>
            <TableCell>
                {sponsor.agreements.map((a, i) => (
                    <AgreementText key={a.id} agreement={a} first={i === 0} />
                ))}
            </TableCell>
        </TableRow>
    );
}
