import { Button } from '@material-ui/core'; 
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import validator from 'norsk-validator';
import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import PullRight from '../../components/PullRight';
import TextInput from '../../components/TextInput';

const useStyles = makeStyles({
    form: {
        display: 'flex',
        flexDirection: 'column'
    },
    card: {
        maxWidth: '500px'
    }
});

const SponsorSchema = Yup.object().shape({
    name: Yup.string().trim().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    name1: Yup.string().trim().min(2, 'Too Short!').max(50, 'Too Long!'),
    email1: Yup.string().trim().email('Invalid email').nullable(),
    email2: Yup.string().trim().email('Invalid email').nullable(),
    custNo: Yup.string().trim().length(5, 'Must be 5 digits').matches(/\d{5}/, 'Must be 5 digits').nullable(),
    birthNumber: Yup.string()
        .trim()
        .length(11, 'Must be 11 digits')
        .test('Valid Birth Number', 'Birth Number is not valid', value => value == null || validator.fodselsnummer(value))
        .nullable(),
    organizationNumber: Yup.string()
        .trim()
        .length(9, 'Must be 9 digits')
        .test(
            'Valid organization Number',
            'Birth Organization is not valid',
            value => value == null || validator.organisasjonsnummer(value)
        )
        .nullable(),

    note: Yup.string().trim().ensure()
});

function toFormValues(sponsor) {
    return {
        name: sponsor.name ?? '',
        name1: sponsor.name1 ?? '',
        name2: sponsor.name2 ?? '',
        email1: sponsor.email1 ?? '',
        email2: sponsor.email2 ?? '',
        custNo: sponsor.custNo ?? '',
        birthNumber: sponsor.birthNumber ?? '',
        isOrganization: !!sponsor.isOrganization,
        organizationNumber: sponsor.organizationNumber ?? '',
        reminder: !!sponsor.reminder,
        note: sponsor.note ?? ''
    };
}

function trimToNull(value) {
    if (value == null) {
        return null;
    }

    const trimmed = value.trim();
    if (trimmed === '') {
        return null;
    }

    return trimmed;
}

function normalize(sponsor, formValues) {
    return {
        ...sponsor,
        name: trimToNull(formValues.name),
        name1: trimToNull(formValues.name1),
        name2: trimToNull(formValues.name2),
        email1: trimToNull(formValues.email1),
        email2: trimToNull(formValues.email2),
        custNo: trimToNull(formValues.custNo),
        birthNumber: trimToNull(formValues.birthNumber),
        isOrganization: !!formValues.isOrganization,
        organizationNumber: trimToNull(formValues.organizationNumber),
        reminder: !!formValues.reminder,
        note: trimToNull(formValues.note)
    };
}

export default function SponsorEditForm({ sponsor, save, showAddAgreement }) {
    const classes = useStyles();
    return (
        <div>
            <Formik
                initialValues={toFormValues(sponsor)}
                validationSchema={SponsorSchema}
                onSubmit={(values, { setSubmitting }) => {
                    const n = normalize(sponsor, values);
                    save(n).then(() => setSubmitting(false));
                }}
            >
                {({ errors, touched, handleSubmit, isSubmitting }) => (
                    <Card className={classes.card}>
                        <CardContent>
                            <Form onSubmit={handleSubmit} className={classes.form}>
                                <TextInput errors={errors} touched={touched} name="name" label="Name" />
                                <TextInput errors={errors} touched={touched} name="name1" label="Name 1" />
                                <TextInput errors={errors} touched={touched} name="name2" label="Name 2" />
                                <TextInput errors={errors} touched={touched} name="email1" label="Email 1" />
                                <TextInput errors={errors} touched={touched} name="email2" label="Email 2" />
                                <TextInput errors={errors} touched={touched} name="custNo" label="CustNo" />
                                <TextInput errors={errors} touched={touched} name="birthNumber" label="Birth Number" />
                                <Field component={CheckboxWithLabel} type="checkbox" name="reminder" Label={{ label: 'Reminder' }} />
                                <TextInput errors={errors} touched={touched} name="note" label="Note" multiline />
                                <Field
                                    component={CheckboxWithLabel}
                                    type="checkbox"
                                    name="isOrganization"
                                    Label={{ label: 'Is Organization' }}
                                />
                                <TextInput errors={errors} touched={touched} name="organizationNumber" label="Organization Number" />
                            </Form>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                                Save
                            </Button>
                            <PullRight/>
                            {showAddAgreement && <Button variant="contained" component={Link} to={`/agreements/new/${sponsor.id}`}>
                                New Agreement
                            </Button>}
                        </CardActions>
                    </Card>
                )}
            </Formik>
        </div>
    );
}
