import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';

function formatNumber(showZero: boolean, value: number) {
    if (Number.isFinite(value)) {
        return showZero || value !== 0 ? value.toLocaleString('no') : '';
    }

    return showZero ? 0 : '';
}

export type TableCellNumberProps = {
    showZero: boolean;
    value: number;
    onEdit?: (balue: number) => void;
};

export default function TableCellNumber({ showZero, value, onEdit }: TableCellNumberProps) {
    const text = formatNumber(showZero, value);
    const [open, setOpen] = React.useState(false);
    const [newValue, setNewValue] = React.useState(value);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setNewValue(Number.parseInt(event.target.value));
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const edit = () => {
        const value = newValue;
        if (onEdit != null && isFinite(value)) {
            onEdit(value);
            handleClose();
        }
    };

    return (
        <TableCell align="right">
            {text}
            {onEdit && (
                <>
                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleClickOpen}>
                        <EditIcon />
                    </IconButton>

                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Ny verdi</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="amount"
                                label="Lunch beløp"
                                type="number"
                                value={newValue}
                                onChange={handleChange}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Avbryt
                            </Button>
                            <Button onClick={edit} color="primary">
                                Lagre
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </TableCell>
    );
}
