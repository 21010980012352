import React, { useState } from 'react';
import { sponsorAdd } from '../../backend/sponsorsApi';
import { receiverAdd } from '../../backend/receiversApi';
import { sponsorTypeAdd } from '../../backend/sponsorTypesApi';
import { agreementsAdd } from '../../backend/agreementsApi';
import { useAuth0 } from '@auth0/auth0-react';
import generate from '../utils/id';
import { asyncForEach } from '../utils/async-utils';

export default function Import() {
    const [file, setFile] = useState();
    const { getAccessTokenSilently } = useAuth0();

    const handleFileRead = fileReader => {
        const content = fileReader.result;
        setFile(content);
    };

    const handleFileChosen = file => {
        const fileReader = new FileReader();
        fileReader.onloadend = () => handleFileRead(fileReader);
        fileReader.readAsText(file);
    };

    const execute = async () => {
        const json = JSON.parse(file);

        const sponsors = createSponsors(json);
        const receivers = createReceivers(json);
        const sponsorTypes = createSponsorTypes(json);
        const agreements = createAgreements(json, sponsors, receivers, sponsorTypes);

        await asyncForEach(sponsors, s => sponsorAdd(getAccessTokenSilently, s));
        await asyncForEach(receivers, r => receiverAdd(getAccessTokenSilently, r));
        await asyncForEach(sponsorTypes, st => sponsorTypeAdd(getAccessTokenSilently, st));
        await asyncForEach(agreements, a => agreementsAdd(getAccessTokenSilently, a));
    };

    function createAgreements(data, sponsors, receivers, sponsorTypes) {
        const agreements = [];
        for (const fireStoreSponsor of data.sponsors) {
            if (fireStoreSponsor.agreements != null) {
                const sponsor = sponsors.find(s => s.fireStoreId === fireStoreSponsor.id);
                if (sponsor) {
                    for (const agreement of fireStoreSponsor.agreements) {
                        agreements.push(createAgreement(agreement, sponsor, receivers, sponsorTypes));
                    }
                }

            }
        }
        return agreements;
    }

    function createAgreement(agreement, sponsor, receivers, sponsorTypes) {
        const receiver = receivers.find(r => r.fireStoreId === agreement.receiver);
        const sponsorType = sponsorTypes.find(st => st.fireStoreId === agreement.sponsorType);

        const result = {
            id: generate(),
            from: agreement.from,
            to: agreement.to,
            amount: agreement.amount,
            yearly: agreement.yearly || false,
            month: agreement.month,
            orderNo: agreement.orderNo,
            sendInvoice: agreement.invoice || false,
            sponsorId: sponsor.id,
            receiverId: receiver != null ? receiver.id : null,
            sponsorTypeId: sponsorType != null ? sponsorType.id : null
        }

        return result;
    }

    function createSponsors(data) {
        const sponsors = data.sponsors.map(s => mapSponsor(s));
        return sponsors;
    }

    function createReceivers(data) {
        const receivers = data.receivers.map(r => ({
            id: generate(),
            name: r.name,
            fireStoreId: r.id
        }));

        return receivers;
    }

    function createSponsorTypes(data) {
        const receivers = data.sponsorTypes.map(r => ({
            id: generate(),
            name: r.name,
            fireStoreId: r.id
        }));

        return receivers;
    }

    const mapSponsor = sponsor => {
        return {
            id: generate(),
            name: sponsor.name,
            name1: sponsor.name1,
            email1: sponsor.email1,
            email2: sponsor.email2,
            reminder: sponsor.reminder,
            note: sponsor.note,
            custNo: sponsor.custNo,
            isOrganization: sponsor.isOrganization,
            organizationNumber: sponsor.organizationNumber,
            birthNumber: sponsor.birthNumber,
            fireStoreId: sponsor.id
        };
    };

    return (
        <div>
            <input type="file" onChange={e => handleFileChosen(e.target.files[0])} />
            <button onClick={execute}>Execute</button>
        </div>
    );
}
