import { Button } from '@material-ui/core';
import format from 'date-fns/format';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import React from 'react';
import { BankAccount } from '../../models/BankAccount';
import { SponsorTransaction } from '../../models/SponsorTransaction';
import { createPostings, createPostingsCsv } from './helpers/createTripletexPostingCsv';

export type TransactionsDownloadProps = {
    transactions: SponsorTransaction[];
    bankAccounts: BankAccount[];
};

export default function TransactionsDownload({ transactions, bankAccounts }: TransactionsDownloadProps) {
    const download = () => {
        const postings = createPostings(transactions, bankAccounts);
        const file = createPostingsCsv(postings);

        const blob = new Blob([file], { type: 'text/csv' });
        saveAs(blob, 'bank-bilag.csv');
    };

    function downloadCsv() {
        const payments = transactions.filter(t => t.sponsor != null && t.in != null).map(p => createCsvLine(p));
        const file = Papa.unparse(payments, { delimiter: '\t' });

        const blob = new Blob([file], { type: 'text/csv' });
        saveAs(blob, 'payments.csv');
    }

    function createCsvLine(payment: SponsorTransaction) {
        var result = {
            id: payment.sponsor?.id,
            custNo: payment.sponsor?.custNo,
            name: payment.sponsor?.name,
            email: payment.sponsor?.email1,
            date: format(payment.date, 'yyyy-MM-dd'),
            amount: payment.in,
        };
        return result;
    }

    return (
        <div>
            <Button variant="contained" onClick={download}>
                Download
            </Button>
            <Button variant="contained" onClick={downloadCsv}>
                Download
            </Button>
        </div>
    );
}
