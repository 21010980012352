import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { giftsForYear } from '../backend/giftsApi';

function useGifts(year: number) {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    return useQuery(
        'gifts',
        async () => {
            return await giftsForYear(year, getAccessTokenSilently);
        },
        { enabled: isAuthenticated }
    );
}

export default useGifts;
