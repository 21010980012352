import { useRecoilValue } from 'recoil';
import { bankTransactionsAtom } from '../../../atoms/atoms';
import useReconsiliationContext from '../../../dataContexts/useReconsiliationContext';
import { AgreementEx } from '../../../models/Agreement';
import { BankAccount } from '../../../models/BankAccount';
import { Sponsor } from '../../../models/Sponsor';
import { isNullOrWhiteSpace } from '../../utils/stringUtils';
import { SponsorTransaction } from '../../../models/SponsorTransaction';

export default function useBankReconsiliation() {
    const { sponsors, agreements, bankAccounts, isLoading, error } = useReconsiliationContext();
    const transactions = useRecoilValue(bankTransactionsAtom);

    let sponsorTransactions: SponsorTransaction[] = [];
    if (transactions) {
        sponsorTransactions = transactions.map(t => {
            const bankAccount = findBankAccount(t.accountNumber, bankAccounts);
            const sponsor = findSponsor(t.text, sponsors);
            const sponsorAgreements = sponsor != null ? agreements.filter(a => a.sponsorId === sponsor.id) : [];
            const agreementFound = sponsorHasAgreement(sponsorAgreements, t.in);
            return {
                ...t,
                sponsor,
                bankAccount,
                agreements: sponsorAgreements,
                agreementFound,
            };
        });
    }

    const result = {
        transactions: sponsorTransactions,
        bankAccounts,
        isLoading,
        error,
    };

    return result;
}

function findSponsor(text: string, sponsors: Sponsor[]) {
    if (text == null) {
        return undefined;
    }

    return sponsors.find(s => isSponsorMatch(s, text));
}

function isSponsorMatch(sponsor: Sponsor, text: string) {
    if (!isNullOrWhiteSpace(sponsor.name) && text.includes(sponsor.name)) {
        return true;
    }

    if (!isNullOrWhiteSpace(sponsor.name1) && text.includes(sponsor.name1)) {
        return true;
    }

    if (!isNullOrWhiteSpace(sponsor.name2) && text.includes(sponsor.name2)) {
        return true;
    }

    return false;
}

function findBankAccount(text: string, bankAccounts: BankAccount[]) {
    return bankAccounts.find(b => b.bankAccount === text);
}

function sponsorHasAgreement(agreements: AgreementEx[], amount: number) {
    if (agreements.find(a => a.isActive && a.amount === amount) != null) {
        return true;
    }

    const sum = agreements
        .filter(a => a.isActive && !a.yearly)
        .map(a => a.amount)
        .reduce((p, c) => p + c, 0);
    return sum === amount;
}
