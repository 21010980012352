import { format } from 'date-fns';
import * as XLSX from 'xlsx';

export type ExcelField<T> = {
    title: string;
    data: (data: T) => string | number | undefined;
};

export type ExcelFields<T> = {
    data: ExcelField<T>[];
};

export function excelDownload<T>(fields: ExcelFields<T>, data: T[], filename: string, includeTimestamp = true) {
    const result: (string | number | undefined)[][] = [];

    // Headers
    result.push(fields.data.map(f => f.title));

    // Data
    for (const e of data) {
        const row: (string | number | undefined)[] = [];
        for (const field of fields.data) {
            row.push(field.data(e));
        }
        result.push(row);
    }

    const ws = XLSX.utils.aoa_to_sheet(result);

    /* generate workbook and add the worksheet */
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    let fn = filename;
    if (includeTimestamp) {
        const dt = new Date();
        fn += '_' + format(dt, "yyyyMMdd'_'HHmmss");
    }
    fn += '.xlsx';

    XLSX.writeFile(wb, fn);
}
