import { isNullOrEmpty } from '../features/utils/stringUtils';
import { toast } from 'react-toastify';
import { Sponsor } from '../models/Sponsor';

export default function useCopyEmails() {
    function copy(sponsors: Sponsor[]) {
        const emails = getEmails(sponsors);
        updateClibord(emails);
    }

    function getEmails(sponsors: Sponsor[]) {
        const allEmails = sponsors
            .map(s => [s.email1, s.email2])
            .flat()
            .filter(s => !isNullOrEmpty(s));
        return Array.from(new Set(allEmails));
    }

    function updateClibord(emails: string[]) {
        const text = emails.join(';');
        const pd: PermissionDescriptor = {name: 'clipboard'};
        navigator.permissions.query(pd).then(result => {
            if (result.state === 'granted' || result.state === 'prompt') {
                navigator.clipboard.writeText(text).then(
                    () => toast.success(`${emails.length} e-post adresser kopiert`),
                    () => toast.error(`Feil kopiering e-post adresser`, { autoClose: false })
                );
            }
        });
    }

    return { copy };
}
