import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Markdown from 'react-markdown';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px',
        backgroundColor: '#e8e8e8',
        borderRadius: '4px'
    },
    label: {
        marginTop: '5px',
        marginBottom: '1px',
        marginLeft: '10px',
        marginRight: '10px',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    value: {
        marginTop: '1px',
        marginBottom: '5px',
        marginLeft: '10px',
        marginRight: '10px',
        fontSize: '16px'
    }
});

export default function MarkdownPropertyView({ label, value, alwaysShow }) {
    const classes = useStyles();

    if (!alwaysShow && (value == null || value === '')) {
        return '';
    }

    return (
        <div className={classes.root}>
            <div className={classes.label}>{label}</div>
            <div className={classes.value}>
                <Markdown source={value} />
            </div>
        </div>
    );
}
