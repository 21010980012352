import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { sponsorById } from '../backend/sponsorsApi';

function useSponsor(id: string | undefined, refetchOnWindowFocus = true) {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    return useQuery(
        ['sponsors', id],
        async () => {
            return id != null ? await sponsorById(id, getAccessTokenSilently) : undefined;
        },
        { enabled: isAuthenticated && id != null, refetchOnWindowFocus }
    );
}

export default useSponsor;
