import { QueryResult } from 'react-query';

export function mergeQueryStatuses(queries: QueryResult<unknown>[]) {
    const errors = queries.map(q => q.error).filter(q => q != null);
    const error = errors.length > 0 ? errors[0] : null;

    const isIdle = queries.map(q => q.isIdle).some(q => q);
    const isLoading = queries.map(q => q.isLoading).some(q => q);
    const isFetching = queries.map(q => q.isFetching).some(q => q);

    const result = {
        isIdle,
        isLoading,
        isFetching,
        error,
    };

    return result;
}
