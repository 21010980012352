export default function convert(settlements) {
    const result = settlements.map(s => convertSettlement(s)).reduce((a, b) => a.concat(b), []);
    return result;
}

export function convertSettlement(settlement)  {
    const lines = [];

    const series = 3;
    const text = `Vipps oppgjør ${settlement.settlementId}`;

    lines.push({
        voucherNumber: settlement.voucherNumber,
        voucherSeries: series,
        date: settlement.date,
        account: 9998,
        department: null,
        customer: null,
        amount: -settlement.grossAmount,
        text
    });

    lines.push({
        voucherNumber: settlement.voucherNumber,
        voucherSeries: series,
        date: settlement.date,
        account: 7771,
        department: null,
        customer: null,
        amount: settlement.fee,
        text
    });

    lines.push({
        voucherNumber: settlement.voucherNumber,
        voucherSeries: series,
        date: settlement.date,
        account: 1920,
        department: null,
        customer: null,
        amount: settlement.netAmount,
        text
    });

    return lines;
}