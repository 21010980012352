import Grid from '@material-ui/core/Grid';
import React from 'react';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import useSponsorsContext from '../../dataContexts/useSponsorsContext';
import { AgreementEx } from '../../models/Agreement';
import { Receiver } from '../../models/Receiver';
import { Sponsor } from '../../models/Sponsor';
import { ReceiverListItem } from './ReceiverListItem';
import ReceiversList from './ReceiversList';

function createListItem(receiver: Receiver, sponsorsAll: Sponsor[], agreementsAll: AgreementEx[]) {
    const agreements = agreementsAll.filter(a => a.receiverId === receiver.id && a.isActive);
    const sponsors = sponsorsAll.filter(s => agreements.find(a => a.sponsorId === s.id));

    const result: ReceiverListItem = {
        id: receiver.id,
        name: receiver.name,
        sponsors: sponsors.length,
        month: agreements.filter(a => !a.yearly).reduce((p, c) => p + c.amount, 0),
        year: agreements.filter(a => a.yearly).reduce((p, c) => p + c.amount, 0),
    };

    return result;
}

export default function Receivers() {
    let { sponsors, agreements, receivers, isLoading, error } = useSponsorsContext();

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <Error error={error} />;
    }

    const whiteHouse = receivers.filter(r => r.whiteHouse).map(r => createListItem(r, sponsors, agreements));
    const other = receivers.filter(r => !r.whiteHouse).map(r => createListItem(r, sponsors, agreements));

    return (
        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
            <Grid item>
                <ReceiversList title="White House" listData={whiteHouse} />
            </Grid>
            <Grid item>
                <ReceiversList title="Andre" listData={other} />
            </Grid>
        </Grid>
    );
}
