import { Hidden } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import { useRecoilState } from 'recoil';
import { filterAtom } from '../../atoms/atoms';

export default function SponsorFilter() {
    const [filter, setFilter] = useRecoilState(filterAtom);

    function handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {
        const newFilter = { ...filter, text: event.target.value };
        setFilter(newFilter);
    }

    function clearTextFilter() {
        const newFilter = { ...filter, text: '' };
        setFilter(newFilter);
    }

    function handleActiveAgreementChange() {
        const newFilter = { ...filter, activeAgreement: !filter.activeAgreement };
        setFilter(newFilter);
    }

    function handleMissingEmailChange() {
        const newFilter = { ...filter, missingEmail: !filter.missingEmail };
        setFilter(newFilter);
    }

    function handleMissingCustNoChange() {
        const newFilter = { ...filter, missingCustNo: !filter.missingCustNo };
        setFilter(newFilter);
    }

    return (
        <Card>
            <CardContent>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                    <Grid item>
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="filter-text">Filter</InputLabel>
                            <OutlinedInput
                                id="filter-text"
                                type="text"
                                value={filter.text}
                                onChange={handleTextChange}
                                autoComplete='off'
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton edge="end" onClick={clearTextFilter}>
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filter.activeAgreement}
                                    onChange={handleActiveAgreementChange}
                                    name="activeAgreement"
                                    color="primary"
                                />
                            }
                            label="Aktive"
                        />
                    </Grid>

                    <Grid item>
                        <Hidden smDown>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.missingEmail}
                                        onChange={handleMissingEmailChange}
                                        name="missingEmail"
                                        color="primary"
                                    />
                                }
                                label="Mangler Email"
                            />
                        </Hidden>
                    </Grid>
                    <Grid item>
                        <Hidden smDown>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.missingCustNo}
                                        onChange={handleMissingCustNoChange}
                                        name="missingCustNo"
                                        color="primary"
                                    />
                                }
                                label="Mangler CustNo"
                            />
                        </Hidden>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
