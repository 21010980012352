import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { queryCache, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sponsorAdd } from '../../backend/sponsorsApi';
import generate from '../utils/id';
import SponsorEditForm from './SponsorEditForm';

const save = async (sponsor, mutate, history) => {
    try {
        await mutate(sponsor);
        toast.success(`"${sponsor.name}" Saved`);
        queryCache.invalidateQueries(['sponsors'], {
            refetchActive: true,
            refetchInactive: false
        });
        history.push(`/sponsors/edit/${sponsor.id}?showAddAgreement=true`);
    } catch (error) {
        toast.error(`Error saving "${sponsor.name}"`, { autoClose: false });
    }
};

export default function SponsorNew() {
    let [id] = useState(generate());
    let [data] = useState({ id });
    const { getAccessTokenSilently } = useAuth0();
    let history = useHistory();

    const [mutate] = useMutation(formData => sponsorAdd(getAccessTokenSilently, formData), { throwOnError: true });

    return <SponsorEditForm sponsor={data} save={sponsor => save(sponsor, mutate, history)} />;
}
