import { TokenGetter } from '../hooks/TokenGetter';
import { Gift } from '../models/Gift';
import { fetchGet, fetchPost } from './api';

export const giftsForYear = async (year: number, tokenGetter: TokenGetter) => {
    return await fetchGet<Gift[]>(`gifts/year/${year}`, tokenGetter);
};

export const giftAdd = async (tokenGetter: TokenGetter, gift: Gift) => {
    return await fetchPost<Gift>('gifts', gift, tokenGetter);
};

export const clearGifts = async (tokenGetter: TokenGetter, year: number) => {
    return await fetchPost<Gift>('gifts/clear/gifts', { year }, tokenGetter);
};

export const clearLoss = async (tokenGetter: TokenGetter, year: number) => {
    return await fetchPost<Gift>('gifts/clear/loss', { year }, tokenGetter);
};
