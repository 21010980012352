import getMonth from 'date-fns/getMonth';
import parse from 'date-fns/parse';

export default function parseVippsFile(input) {
    const lines = input.split(/\n/).filter(l => l.startsWith('SettlementInfo'));

    const settlements = lines
        .map(l => convertLine(l))
        .filter(s => s.settlementId != null)
        .sort((a, b) => a.settlementId - b.settlementId);

    return settlements;
}

function convertLine(line) {
    const tokens = line.split(',');

    const date = parse(tokens[3], 'dd.MM.yyyy', new Date());
    const settlement = {
        date,
        voucherNumber: getMonth(date) + 1,
        settlementId: strToNum(tokens[4]),
        grossAmount: strToNum(tokens[6]),
        fee: -strToNum(tokens[8]),
        netAmount: strToNum(tokens[10]),
    };
    return settlement;
}

function strToNum(input) {
    const value = parseFloat(input);
    if (!isNaN(value)) {
        return value;
    }

    return null;
}
