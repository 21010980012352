import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { agreementById } from '../backend/agreementsApi';

function useAgreement(id: string, refetchOnWindowFocus = true) {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    return useQuery(
        ['agreements', id],
        async () => {
            return await agreementById(id, getAccessTokenSilently);
        },
        { enabled: isAuthenticated && id != null, refetchOnWindowFocus }
    );
}

export default useAgreement;
