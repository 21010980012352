import { useAuth0 } from '@auth0/auth0-react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { DropzoneArea } from 'material-ui-dropzone';
import { useState } from 'react';
import { queryCache } from 'react-query';
import { clearGifts, clearLoss, giftAdd } from '../../backend/giftsApi';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import useGiftsImportContext from '../../dataContexts/useGiftsImportContext';
import { GiftUpdate } from '../../models/Gift';
import { Sponsor } from '../../models/Sponsor';
import useBankReconsiliation from '../bank-reconsiliation/helpers/useBankReconsiliation';
import { asyncForEach } from '../utils/async-utils';
import generate from '../utils/id';
import parseBalanceFile, { BalanceLine } from './customer-balance-import';
import parseLossFile from './loss-import';

export type ImportStatus = {
    text: string;
    status: string;
};

export default function TaxSettlementImport() {
    const [progress, setProgress] = useState<number>(0);
    const [status, setStatus] = useState<ImportStatus[]>([]);
    const { transactions, isLoading: bankLoading, error: bankError } = useBankReconsiliation();

    const { getAccessTokenSilently } = useAuth0();
    const { sponsors, sponsorsCustNoMap, gifts, isLoading, error } = useGiftsImportContext(2023);

    if (isLoading || bankLoading) {
        return <Loading />;
    }

    if (error || bankError) {
        return <Error error={error} />;
    }

    const createUpdate = (data: BalanceLine): GiftUpdate | null => {
        let sponsor: Sponsor | undefined;

        if (data.id != null) {
            sponsor = sponsors.find(s => s.id === data.id);
        }
        if (sponsor == null && data.custNo != null) {
            sponsor = sponsorsCustNoMap.get(data.custNo);
        }
        if (sponsor === undefined || sponsor === null) {
            return null;
        }

        let gift = gifts.find(g => g.year === 2023 && g.sponsorId === sponsor?.id);
        const update: GiftUpdate = {
            id: gift?.id ?? generate(),
            name: sponsor.name,
            sponsorId: sponsor.id,
            year: 2023,
            gift: data.amount,
        };

        return update;
    };

    const createUpdates = (data: BalanceLine[]): GiftUpdate[] => {
        return data.map(d => createUpdate(d)).filter(d => d != null) as GiftUpdate[];
    };

    const createLossUpdate = (data: BalanceLine): GiftUpdate | null => {
        if (data.custNo == null) {
            return null;
        }
        const sponsor = sponsorsCustNoMap.get(data.custNo);
        if (sponsor == null) {
            return null;
        }

        let gift = gifts.find(g => g.year === 2023 && g.sponsorId === sponsor.id);
        const update = {
            id: gift?.id ?? generate(),
            name: sponsor.name,
            sponsorId: sponsor.id,
            year: 2023,
            loss: data.amount,
        };
        return update;
    };

    const createLossUpdates = (data: BalanceLine[]): GiftUpdate[] => {
        const sum = new Map();

        data.forEach(element => {
            if (!sum.has(element.custNo)) {
                sum.set(element.custNo, element.amount);
            } else {
                const existing = sum.get(element.custNo);
                sum.set(element.custNo, existing + element.amount);
            }
        });

        const array = Array.from(sum, ([custNo, amount]) => ({ custNo, amount }));
        return array.map(d => createLossUpdate(d)).filter(d => d != null) as GiftUpdate[];
    };

    const saveAndUpdateProgress = async (update: GiftUpdate, index: number, length: number) => {
        const status = {
            text: update.name,
            status: '',
        };
        setStatus(x => x.concat([status]));

        await giftAdd(getAccessTokenSilently, update);

        setProgress(100 * ((index + 1) / length));
        status.status = 'Lagret';
        setStatus(x => [...x]);
    };

    const saveCustomerBalance = async (data: BalanceLine[]) => {
        setProgress(0);
        setStatus([]);

        await clearGifts(getAccessTokenSilently, 2023);
        invalidateQueries();

        const updates = createUpdates(data);
        await asyncForEach(updates, (s, i, a) => saveAndUpdateProgress(s, i, a.length));
        invalidateQueries();
    };

    const saveLoss = async (data: BalanceLine[]) => {
        setProgress(0);
        setStatus([]);

        await clearLoss(getAccessTokenSilently, 2023);
        invalidateQueries();

        const updates = createLossUpdates(data);
        await asyncForEach(updates, (s, i, a) => saveAndUpdateProgress(s, i, a.length));
        invalidateQueries();
    };

    const invalidateQueries = () => {
        queryCache.invalidateQueries(['gifts'], {
            refetchActive: true,
            refetchInactive: false,
        });
    };

    const parseAndSave = (data: string) => {
        if (isBalanceFile(data)) {
            const content = parseBalanceFile(data);
            saveCustomerBalance(content);
        }

        if (isLossFile(data)) {
            const content = parseLossFile(data);
            saveLoss(content);
        }
    };

    const isLossFile = (data: string) => {
        const regexp = /^Bilagsnummer/;
        return regexp.test(data);
    };

    const isBalanceFile = (data: string) => {
        const regexp = /^Nummer/;
        return regexp.test(data);
    };

    function handleChange(files: File[]) {
        files.forEach(file => {
            const reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = () => parseAndSave(reader.result as string);

            reader.readAsText(file, 'UTF-8');
        });
    }

    async function saveBankPayments() {
        const amounts = new Map<string, { sponsor: Sponsor; amount: number }>();
        for (const transaction of transactions) {
            if (transaction.sponsor != null && transaction.in != null && transaction.in > 0) {
                let amount = amounts.get(transaction.sponsor!.id);
                if (amount === undefined) {
                    amount = {
                        sponsor: transaction.sponsor!,
                        amount: 0,
                    };
                    amounts.set(transaction.sponsor!.id, amount);
                }
                amount.amount += transaction.in;
            }
        }

        const data = Array.from(amounts.values()).map(v => ({ id: v.sponsor?.id, amount: Math.floor(v.amount) }));

        setProgress(0);
        setStatus([]);

        await clearGifts(getAccessTokenSilently, 2023);
        invalidateQueries();

        const updates = createUpdates(data);
        await asyncForEach(updates, (s, i, a) => saveAndUpdateProgress(s, i, a.length));
        invalidateQueries();
    }

    return (
        <div>
            <DropzoneArea acceptedFiles={['text/csv']} filesLimit={1} onChange={handleChange} />
            <Button onClick={saveBankPayments}>Save bank payments</Button>
            <CircularProgress variant="determinate" value={progress} />

            <TableContainer component={Paper}>
                <Table aria-label="Sponsor table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Navn</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {status.map(s => (
                            <TableRow>
                                <TableCell>{s.text}</TableCell>
                                <TableCell>{s.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
