import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import useSponsorsContext from '../../dataContexts/useSponsorsContext';
import YearliAgreementItem from './YearliAgreementItem';

function sortSponsors(sponsors) {
    if (sponsors == null) {
        return [];
    }

    const result = [...sponsors];
    result.sort((a, b) => a.sponsor.name.localeCompare(b.sponsor.name));
    return result;
}

export default function YearlyAgreements() {
    const { sponsors, agreements: allAgreements, receivers, sponsorTypes, isLoading, error } = useSponsorsContext();

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <Error error={error} />;
    }

    const agreements = allAgreements.filter(a => a.isActive && a.yearly);

    const sponsorsMap = new Map(sponsors.map(s => [s.id, s]));
    const receiversMap = new Map(receivers.map(r => [r.id, r]));
    const sponsorTypesMap = new Map(sponsorTypes.map(r => [r.id, r]));

    const items = agreements.map(a => ({
        agreement: a,
        sponsor: sponsorsMap.get(a.sponsorId),
        sponsorType: sponsorTypesMap.get(a.sponsorTypeId),
        receiver: receiversMap.get(a.receiverId),
    }));

    const sorted = sortSponsors(items);

    return (
        <TableContainer component={Paper}>
            <Table aria-label="Sponsor table">
                <TableHead>
                    <TableRow>
                        <TableCell>Sponsor</TableCell>
                        <TableCell>Agreements</TableCell>
                        <TableCell>Receiver</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Month</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sorted.map(s => (
                        <YearliAgreementItem key={s.agreement.id} item={s}></YearliAgreementItem>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
