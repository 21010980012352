import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { agreementsAll } from '../backend/agreementsApi';

function useAgreements() {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    return useQuery(
        'agreements',
        async () => {
            return await agreementsAll(getAccessTokenSilently);
        },
        { enabled: isAuthenticated }
    );
}

export default useAgreements;
