import { TokenGetter } from '../hooks/TokenGetter';
import { Sponsor } from '../models/Sponsor';
import { fetchDelete, fetchGet, fetchPost, fetchPut } from './api';

export const sponsorById = async (id: string, tokenGetter: TokenGetter) => {
    return await fetchGet<Sponsor>(`sponsors/${id}`, tokenGetter);
};

export const sponsorsAll = async (tokenGetter: TokenGetter) => {
    return await fetchGet<Sponsor[]>('sponsors', tokenGetter);
};

export const sponsorAdd = async (tokenGetter: TokenGetter, sponsor: Sponsor) => {
    return await fetchPost<Sponsor>('sponsors', sponsor, tokenGetter);
};

export const sponsorUpdate = async (tokenGetter: TokenGetter, sponsor: Sponsor) => {
    return await fetchPut<Sponsor>('sponsors', sponsor, tokenGetter);
};

export const sponsorDelete = async (tokenGetter: TokenGetter, sponsor: Sponsor): Promise<any> => {
    return await fetchDelete(`sponsors/${sponsor.id}`, tokenGetter);
};
