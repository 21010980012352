import { Sponsor } from '../../models/Sponsor';
import { SponsorGift } from '../../models/SponsorGift';
import { isNotNullOrWhiteSpace, isNullOrWhiteSpace } from './stringUtils';

export function hasPublicId(sponsor: Sponsor): boolean {
    return isNotNullOrWhiteSpace(sponsor?.birthNumber) || isNotNullOrWhiteSpace(sponsor?.organizationNumber);
}

export function includeInRf1301(sponsorGift: SponsorGift): boolean {
    return hasPublicId(sponsorGift.sponsor) && sponsorGift.sum >= 500;
}

export function includeSponsor(sponsor: Sponsor, filter: RegExp) {
    if (!isNullOrWhiteSpace(sponsor.name)) {
        const result = filter.test(sponsor.name);
        if (result) {
            return true;
        }
    }

    if (!isNullOrWhiteSpace(sponsor.name1)) {
        const result = filter.test(sponsor.name1);
        if (result) {
            return true;
        }
    }

    if (!isNullOrWhiteSpace(sponsor.name2)) {
        const result = filter.test(sponsor.name2);
        if (result) {
            return true;
        }
    }

    if (!isNullOrWhiteSpace(sponsor.email1)) {
        const result = filter.test(sponsor.email1);
        if (result) {
            return true;
        }
    }

    if (!isNullOrWhiteSpace(sponsor.email2)) {
        const result = filter.test(sponsor.email2);
        if (result) {
            return true;
        }
    }

    if (!isNullOrWhiteSpace(sponsor.birthNumber)) {
        const result = filter.test(sponsor.birthNumber);
        if (result) {
            return true;
        }
    }

    return false;
}