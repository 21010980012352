import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { queryCache, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { agreementsAdd } from '../../backend/agreementsApi';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import useNewAgreementContext from '../../dataContexts/useNewAgreementContext';
import generate from '../utils/id';
import AgreementForm from './AgreementForm';

const save = async (sponsor, agreement, mutate) => {
    try {
        const data = { ...agreement, sponsorId: sponsor.id };
        await mutate(data);
        toast.success(`"${sponsor.name}" Saved`);
        queryCache.invalidateQueries(['agreements'], {
            refetchActive: true,
            refetchInactive: false
        });
    } catch (error) {
        toast.error(`Error saving agreement for "${sponsor.name}"`, { autoClose: false });
    }
};

export default function AgreementNew() {
    const { getAccessTokenSilently } = useAuth0();
    let { sponsorId } = useParams();
    let [agreementId] = useState(generate());
    let [agreement] = useState({ id: agreementId });
    let { sponsor, receivers, sponsorTypes, isLoading, error } = useNewAgreementContext(sponsorId);

    const [mutate] = useMutation(formData => agreementsAdd(getAccessTokenSilently, formData), { throwOnError: true });

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <Error error={error} />;
    }

    return (
        <AgreementForm
            sponsor={sponsor}
            agreement={agreement}
            receivers={receivers}
            sponsorTypes={sponsorTypes}
            save={agreement => save(sponsor, agreement, mutate)}
        ></AgreementForm>
    );
}
