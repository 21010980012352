export function isNullOrEmpty(value: string): boolean {
    return value == null || 0 === value.length;
}

export function isNotNullOrEmpty(value: string): boolean {
    return !isNullOrEmpty(value);
}

export function isNullOrWhiteSpace(value: string): boolean {
    return value == null || /^\s*$/.test(value);
}

export function isNotNullOrWhiteSpace(value: string): boolean {
    return !isNullOrWhiteSpace(value);
}
