import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { Link } from 'react-router-dom';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import useSponsorsContext from '../../dataContexts/useSponsorsContext';

function createListItem(sponsorType, sponsorsAll, agreementsAll) {
    const agreements = agreementsAll.filter(a => a.sponsorTypeId === sponsorType.id && a.isActive);
    const sponsors = sponsorsAll.filter(s => agreements.find(a => a.sponsorId === s.id));

    const result = {
        id: sponsorType.id,
        name: sponsorType.name,
        sponsors: sponsors.length,
        month: agreements.filter(a => !a.yearly).reduce((p, c) => p + c.amount, 0),
        year: agreements.filter(a => a.yearly).reduce((p, c) => p + c.amount, 0)
    };

    return result;
}

const useStyles = makeStyles({
    table: {
        minWidth: 100,
        maxWidth: 600
    },
    count: {
        width: 100
    },
    amount: {
        width: 150
    }
});

export default function SponsorTypes() {
    const classes = useStyles();

    let { sponsors, agreements, sponsorTypes, isLoading, error } = useSponsorsContext();

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <Error error={error} />;
    }

    const listData = sponsorTypes.map(st => createListItem(st, sponsors, agreements));

    listData.sort((a, b) => a.name.localeCompare(b.name));

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Mottaker</TableCell>
                        <TableCell>Fadder</TableCell>
                        <TableCell>Månedlig</TableCell>
                        <TableCell>Årlig</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {listData.map(sponsorType => (
                        <TableRow key={sponsorType.id}>
                            <TableCell>
                                <Link to={`/sponsor-types/${sponsorType.id}`}>{sponsorType.name}</Link>
                            </TableCell>
                            <TableCell align="right" className={classes.count}>
                                {sponsorType.sponsors}
                            </TableCell>
                            <TableCell align="right" className={classes.amount}>
                                {sponsorType.month}
                            </TableCell>
                            <TableCell align="right" className={classes.amount}>
                                {sponsorType.year}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
