import { atom } from 'recoil';
import { BankTransaction } from '../models/BankTransaction';
import { SponsorFilterModel } from '../models/SponsorFilterModel';
import { TaxFilterModel } from '../models/TaxFilterModel';

export const showDrawer = atom<boolean>({
    key: 'showDrawer',
    default: false,
});

export const filterAtom = atom<SponsorFilterModel>({
    key: 'filter',
    default: {
        text: '',
        activeAgreement: false,
        missingEmail: false,
        missingCustNo: false,
    },
});

export const taxFilterAtom = atom<TaxFilterModel>({
    key: 'taxFilter',
    default: {
        text: '',
        withMissingSosialSecurityNumber: false,
        withMissingEmail: false,
        onlyWillBeReported: false,
        onlyWithAmount: false,
        lunchPartner: false,
    },
});

export const bankTransactionsAtom = atom<BankTransaction[]>({
    key: 'bankTransactions',
    default: [],
});

export const vippsTransactionsAtom = atom({
    key: 'vippsTransactions',
    default: [],
});
