import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
    root: {
        flex: '1 1 auto'
    }
}));

export default function PullRight() {
    const classes = useStyles();
    return <div className={classes.root}></div>;
}
