import { TokenGetter } from '../hooks/TokenGetter';
import { SponsorType } from '../models/SponsorType';
import { fetchGet, fetchPost } from './api';

export const sponsorTypesAll = async (tokenGetter: TokenGetter) => {
    return await fetchGet<SponsorType[]>('sponsor-types', tokenGetter);
};

export const sponsorTypeAdd = async (tokenGetter: TokenGetter, sponsorType: SponsorType) => {
    return await fetchPost<SponsorType>('sponsor-types', sponsorType, tokenGetter);
};
