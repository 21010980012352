import { useAuth0 } from '@auth0/auth0-react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { queryCache } from 'react-query';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { giftAdd } from '../../backend/giftsApi';
import TableCellNumber from '../../components/TableCellNumber';
import { GiftUpdate } from '../../models/Gift';
import { SponsorGift } from '../../models/SponsorGift';
import { isNullOrWhiteSpace } from '../utils/stringUtils';

export type TaxSettlementRowProps = {
    sponsorGift: SponsorGift;
};

export default function TaxSettlementRow({ sponsorGift }: TaxSettlementRowProps) {
    const { getAccessTokenSilently } = useAuth0();

    const sponsor = sponsorGift.sponsor;
    const missing = isNullOrWhiteSpace(sponsor.birthNumber);

    const updateData = async (update: object) => {
        const data: GiftUpdate = {
            ...update,
            id: sponsorGift.giftId,
            sponsorId: sponsorGift.sponsor.id,
            year: 2023,
            name: sponsorGift.sponsor.name,
        };

        try {
            await giftAdd(getAccessTokenSilently, data);

            queryCache.invalidateQueries(['gifts'], {
                refetchActive: true,
                refetchInactive: true,
            });
            toast.success(`Oppdatering av "${sponsorGift.sponsor.name}" lagret`);
        } catch (e) {
            toast.error(`Oppdatering av "${sponsorGift.sponsor.name}" feilet`);
        }
    };

    const onEditLoss = async (newValue: number) => {
        const update = { lunch: newValue };
        updateData(update);
    };

    const onEditAdjust = async (newValue: number) => {
        const update = { adjust: newValue };
        updateData(update);
    };

    return (
        <TableRow>
            <TableCell>
                <Link to={`/sponsors/${sponsor.id}`}>{sponsor.name}</Link>
            </TableCell>
            <TableCell>{missing ? <HelpOutlineIcon /> : ''}</TableCell>
            <TableCellNumber showZero={sponsorGift.haveData} value={sponsorGift.gift} />
            <TableCellNumber showZero={sponsorGift.haveData} value={sponsorGift.lunch} onEdit={onEditLoss} />
            <TableCellNumber showZero={sponsorGift.haveData} value={sponsorGift.loss} />
            <TableCellNumber showZero={sponsorGift.haveData} value={sponsorGift.adjust} onEdit={onEditAdjust} />
            <TableCellNumber showZero={sponsorGift.haveData} value={sponsorGift.sum} />
        </TableRow>
    );
}
