import { useMemo } from 'react';
import { mergeQueryStatuses } from '../features/utils/query-utils';
import useGifts from '../hooks/useGifts';
import useSponsors from '../hooks/useSponsors';
import { Sponsor } from '../models/Sponsor';

function useGiftsImportContext(year: number) {
    const sponsors = useSponsors();
    const gifts = useGifts(year);

    const sponsorsCustNoMap = useMemo(() => createSponsorsCustNoMap(sponsors.data), [sponsors.data]);

    const merge = mergeQueryStatuses([sponsors, gifts]);

    const result = {
        sponsors: sponsors.data ?? [],
        gifts: gifts.data ?? [],
        sponsorsCustNoMap,
        isIdle: merge.isIdle,
        isLoading: merge.isLoading,
        isFetching: merge.isFetching,
        error: merge.error,
    };

    return result;
}

function createSponsorsCustNoMap(sponsors?: Sponsor[]): Map<string, Sponsor> {
    if (sponsors == null) {
        return new Map();
    }
    return new Map(sponsors.filter(s => s.custNo != null).map(s => [s.custNo, s]));
}

export default useGiftsImportContext;
