import format from 'date-fns/format';
import getMonth from 'date-fns/getMonth';
import { BankAccount } from '../../../models/BankAccount';
import { Posting } from '../../../models/Posting';
import { SponsorTransaction } from '../../../models/SponsorTransaction';

const paymentSeries = 4;
const transfearSeries = 2;
// const vippsSeries = 3;

export function createPostings(transactions: SponsorTransaction[], bankAccounts: BankAccount[]): Posting[] {
    const result = transactions
        .map(t => getPostings(t, bankAccounts))
        .filter(t => t.length > 0)
        .flat();

    result.sort((a, b) => a.date.getTime() - b.date.getTime());
    return result;
}

function getPostings(transaction: SponsorTransaction, bankAccounts: BankAccount[]): Posting[] {
    let result = getPaymentPostings(transaction)
        .concat(getTransfearPostings(transaction, bankAccounts))
        .concat(getFacebookPostings(transaction))
        .concat(getTransfearToGambiaPostings(transaction));

    return result;
}

function getTransfearPostings(transaction: SponsorTransaction, bankAccounts: BankAccount[]): Posting[] {
    if (transaction.in != null && transaction.bankAccount != null && transaction.transaksjonsType === 'Kontoregulering') {
        const account = findAccount(transaction.text, bankAccounts);
        if (account != null) {
            let text = `Overføring fra konto ${account.name} til konto ${transaction.bankAccount.name}`;

            return createPostingPair(
                transaction.date,
                text,
                transaction.bankAccount.ledgerAccount,
                account.ledgerAccount,
                transaction.in,
                transfearSeries
            );
        }
    }

    return [];
}

function findAccount(text: string, bankAccounts: BankAccount[]): BankAccount | null {
    for (const account of bankAccounts) {
        const searchFor = RegExp(`Fra.* ${account.bankAccount}`);
        if (text.match(searchFor)) {
            return account;
        }
    }

    return null;
}

function getFacebookPostings(transaction: SponsorTransaction): Posting[] {
    if (
        transaction.in != null &&
        transaction.bankAccount != null &&
        transaction.transaksjonsType === 'Overførsel utland' &&
        transaction.text.match(/Facebook Payments/)
    ) {
        let text = `Overføring fra Facebook`;

        return createPostingPair(transaction.date, text, transaction.bankAccount.ledgerAccount, 3932, transaction.in, transfearSeries);
    }

    return [];
}

function getTransfearToGambiaPostings(transaction: SponsorTransaction): Posting[] {
    if (transaction.out != null && transaction.bankAccount != null) {
        if (transaction.text.match(/Tvenning Telebank/)) {
            let text = `Overføring til Gambia`;

            return createPostingPair(transaction.date, text, 1940, 1920, -transaction.out, transfearSeries);
        }
    }

    return [];
}

export function getPaymentPostings(transaction: SponsorTransaction): Posting[] {
    if (transaction.sponsor?.custNo == null || transaction.in == null || transaction.bankAccount == null) {
        return [];
    }

    let text = `Payment from ${transaction.sponsor.name}`;
    if (transaction.sponsor.name !== transaction.text) {
        text += `(${transaction.text})`;
    }

    return createPostingPair(
        transaction.date,
        text,
        transaction.bankAccount.ledgerAccount,
        1500,
        transaction.in,
        paymentSeries,
        transaction.sponsor.custNo
    );
}

function createPostingPair(
    date: Date,
    text: string,
    debit: number,
    credit: number,
    amount: number,
    voucherSeries: number,
    customer?: string
): Posting[] {
    const voucherNumber = getMonth(date) + 1;

    const d: Posting = {
        date,
        account: debit,
        text,
        voucherNumber,
        voucherSeries,
        amount,
    };

    const k: Posting = {
        date,
        account: credit,
        text,
        voucherNumber,
        voucherSeries,
        customer,
        amount: -amount,
    };

    return [d, k];
}

export function createPostingsCsv(postings: Posting[]) {
    const lines = postings.map(l => createPostingCsv(l));
    return lines.join('\n');
}

function createPostingCsv(posting: Posting) {
    // GBAT10;6;20170509;1;1;1;9998;1;-598.00;0;0;;;;;;;;;;Text;;;;;;T
    const dt = format(posting.date, 'yyyyMMdd');

    const result = [
        'GBAT10', // 1
        `${posting.voucherNumber}`, // 2
        `${dt}`, // 3
        `${posting.voucherSeries}`, // 4
        '1', // 5
        '1', // 6
        `${posting.account}`, // 7
        '1', // 8
        posting.amount.toFixed(2), // 9
        posting.customer != null ? `${posting.customer}` : '0', // 10
        '0', // 11
        '', // 12
        '', // 13
        '', // 14
        '', // 15
        '', // 16
        '', // 17
        '', // 18
        '', // 19
        '', // 20
        posting.text, // 21
        '', // 22
        '', // 23
        '', // 24
        posting.department != null ? `${posting.department}` : '0', // 25
        '', // 26
        'T', // 27
    ];

    return result.join(';');
}
