import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReceiverListItem } from './ReceiverListItem';

export type ReceiversListProps = {
    title: string;
    listData: ReceiverListItem[];
};

export default function ReceiversList({ title, listData }: ReceiversListProps) {
    return (
        <Container maxWidth="sm" disableGutters={true}>
            <Card>
                <CardContent>
                    <Typography variant="h5" component="h2">
                        {title}
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Mottaker</TableCell>
                                    <TableCell>Faddere</TableCell>
                                    <TableCell>Månedlig</TableCell>
                                    <TableCell>Årlig</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listData.map(receiver => (
                                    <TableRow key={receiver.id}>
                                        <TableCell>
                                            <Link to={`/receivers/${receiver.id}`}>{receiver.name}</Link>
                                        </TableCell>
                                        <TableCell align="right">{receiver.sponsors}</TableCell>
                                        <TableCell align="right">{receiver.month}</TableCell>
                                        <TableCell align="right">{receiver.year}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Container>
    );
}
