import React from 'react';

export default function Error({ error }) {
    if (error != null) {
        if (typeof error === 'string') {
            return <div>{error}</div>;
        }

        const message = JSON.stringify(error);
        return <div>{message}</div>;
    }

    return <div>Error loading data.</div>;
}
