import { useAuth0 } from '@auth0/auth0-react';
import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RecoilRoot } from 'recoil';
import './App.css';
import Content from './features/app/Content';
import Heading from './features/app/Heading';
import ScrollToTop from './features/app/ScrollToTop';
import SideMenu from './features/app/SideMenu';
import WelcomePage from './features/app/WelcomePage';

function App() {
    const { isAuthenticated } = useAuth0();

    if (!isAuthenticated) {
        return <WelcomePage />;
    }

    return (
        <Router>
            <RecoilRoot>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ScrollToTop></ScrollToTop>
                    <CssBaseline />
                    <Heading></Heading>
                    <SideMenu></SideMenu>
                    <div className="spacer"></div>
                    <Content></Content>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        pauseOnFocusLoss
                        pauseOnHover
                    />
                </MuiPickersUtilsProvider>
            </RecoilRoot>
        </Router>
    );
}

export default App;
