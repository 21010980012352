import { makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';


const useStyles = makeStyles({
    root: {
        marginLeft: 0
    }
});

export default function CheckboxPropertyView({ label, value }) {
    const classes = useStyles();

    return <FormControlLabel className={classes.root} control={<Checkbox checked={value} />} label={label} />;
}
