import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

export default function Logout() {
    const { isLoading, logout } = useAuth0();

    if (!isLoading) {
        logout({ returnTo: window.location.origin });
    }
    return <div>Logging out...</div>;
}
