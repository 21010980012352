import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import FileContent from './FileContent';
import FileImport from './FileImport';
import VippsPostingsDownload from './VippsPostingsDownload';

function TabPanel(props) {
    const { children, value, index } = props;

    if (value !== index) {
        return null;
    }

    return (
        <div role="tabpanel" id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
            <Box p={3}>{children}</Box>
        </div>
    );
}

export default function VippsReconsiliation() {
    const [activePage, setActivePage] = React.useState(0);

    const handleChange = (event, newValue) => {
        setActivePage(newValue);
    };

    return (
        <div>
            <AppBar position="static">
                <Tabs variant="scrollable" scrollButtons="auto" value={activePage} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="File Import" />
                    <Tab label="File Content" />
                    <Tab label="Download" />
                </Tabs>
            </AppBar>

            <TabPanel index={activePage} value={0}>
                <FileImport/>
            </TabPanel>

            <TabPanel index={activePage} value={1}>
                <FileContent/>
            </TabPanel>

            <TabPanel index={activePage} value={2}>
                <VippsPostingsDownload/>
            </TabPanel>
        </div>
    );
}
