import { mergeQueryStatuses } from '../features/utils/query-utils';
import useReceivers from '../hooks/useReceivers';
import useSponsor from '../hooks/useSponsor';
import useSponsorsTypes from '../hooks/useSponsorTypes';

function useNewAgreementContext(sponsorId: string) {
    const sponsor = useSponsor(sponsorId);
    const receivers = useReceivers();
    const sponsorTypes = useSponsorsTypes();

    const merge = mergeQueryStatuses([sponsor, receivers, sponsorTypes]);

    const result = {
        sponsor: sponsor.data,
        receivers: receivers.data ?? [],
        sponsorTypes: sponsorTypes.data ?? [],
        isIdle: merge.isIdle,
        isLoading: merge.isLoading,
        error: merge.error,
    };

    return result;
}

export default useNewAgreementContext;
