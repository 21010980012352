import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import logo from './logo.svg';
import './WelcomePage.css';
import LoginButton from './LoginButton';

export default function WelcomePage() {
    const { isLoading, error } = useAuth0();

    const showLogin=!isLoading && error == null;

    return (
        <div className="page">
            <div className="title"></div>
            <img src={logo} alt="Logo"></img>

            <div className="status">
                {isLoading && <div>Is loading Auth0</div>}
                {error && <div>{error}</div>}
                {showLogin && <LoginButton></LoginButton>}
            </div>
        </div>
    );
}
