import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { sponsorTypesAll } from '../backend/sponsorTypesApi';

function useSponsorsTypes() {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    return useQuery(
        'sponsorTypes',
        async () => {
            return await sponsorTypesAll(getAccessTokenSilently);
        },
        { staleTime: Infinity, cacheTime: Infinity, enabled: isAuthenticated }
    );
}

export default useSponsorsTypes;
