import { Auth0Provider } from '@auth0/auth0-react';
// import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

// const onRedirectCallback = appState => {
//     createBrowserHistory.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
// };

ReactDOM.render(
    <React.StrictMode>
        <Auth0Provider
            domain="gambia-care.eu.auth0.com"
            clientId="KzERdqgOXKKQzxHnX3ADuMyyxqRT0LbZ"
            redirectUri={window.location.origin}
            // onRedirectCallback={onRedirectCallback}
            audience="https://gambiacare.herokuapp.com/api"
            scope="read:sponsors write:sponsors http://gcac.no/"
            useRefreshTokens={true}
        >
            <App />
        </Auth0Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
