import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import React from 'react';
import useAltinn from '../../hooks/useAltinn';
import useCopyEmails from '../../hooks/useCopyEmails';
import { SponsorGift } from '../../models/SponsorGift';
import { excelDownload, ExcelField, ExcelFields } from '../utils/excelDownload';

function downloadSponsors(sponsors: SponsorGift[]) {
    const data: ExcelField<SponsorGift>[] = [
        { title: 'Navn', data: x => x.sponsor.name },
        { title: 'Fødselsnummber', data: x => x.sponsor.birthNumber },
        { title: 'E-Post', data: x => x.sponsor.email1 },
        { title: 'Gaver', data: x => x.gift },
        { title: 'Lunch', data: x => x.lunch },
        { title: 'Justering', data: x => x.adjust },
        { title: 'Tap', data: x => x.loss },
        { title: 'Sum', data: x => x.sum },
    ];
    const fields: ExcelFields<SponsorGift> = { data };

    excelDownload(fields, sponsors, 'Årsoppgave', true);
}

type TaxSettlementActionsProps = {
    sponsorsGifts: SponsorGift[];
};

export default function TaxSettlementActions({ sponsorsGifts }: TaxSettlementActionsProps) {
    const { copy } = useCopyEmails();
    const { downloadRf1301 } = useAltinn();

    function download() {
        downloadSponsors(sponsorsGifts);
    }

    function copyEmails() {
        copy(sponsorsGifts.map(s => s.sponsor));
    }

    function downloadAltinn() {
        downloadRf1301(sponsorsGifts);
    }

    return (
        <Card>
            <CardActions>
                <Button variant="contained" onClick={download}>
                    Last ned Excel
                </Button>
                <Button variant="contained" onClick={copyEmails}>
                    Kopier e-post adresser
                </Button>
                <Button variant="contained" onClick={downloadAltinn}>
                    Last ned RF1301
                </Button>
            </CardActions>
        </Card>
    );
}
