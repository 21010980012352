import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, Select } from 'formik-material-ui';
import { DatePicker } from 'formik-material-ui-pickers';
import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import TextInput from '../../components/TextInput';

const useStyles = makeStyles({
    form: {
        display: 'flex',
        flexDirection: 'column'
    },
    card: {
        maxWidth: '500px'
    },
    formControl: {
        marginBottom: '20px'
    }
});

const AgreementSchema = Yup.object().shape({
    month: Yup.number().min(1, 'Must be >= 1').max(12, 'Must be <=12').nullable(),
    orderNo: Yup.number().nullable(),
    amount: Yup.number().min(1, 'Must be >= 1')
});

function toFormValues(agreement) {
    return {
        from: agreement.from ? parseISO(agreement.from) : null,
        to: agreement.to ? parseISO(agreement.to) : null,
        amount: agreement.amount ?? '',
        yearly: !!agreement.yearly,
        month: agreement.month ?? '',
        orderNo: agreement.orderNo ?? '',
        sendInvoice: !!agreement.sendInvoice,
        hasVariableAmount: !!agreement.hasVariableAmount,
        sponsorType: agreement.sponsorTypeId ?? 'none',
        receiver: agreement.receiverId ?? 'none'
    };
}

function normalizeNumber(value) {
    const num = parseInt(value);
    if (Number.isFinite(num)) {
        return value;
    }
    return null;
}

function normalizeSelect(value) {
    if (value === '' || value === 'none') {
        return null;
    }

    return value;
}

function normalize(agreement, formValues) {
    return {
        ...agreement,
        from: formValues.from ? formatISO(formValues.from) : null,
        to: formValues.to ? formatISO(formValues.to) : null,
        amount: normalizeNumber(formValues.amount),
        month: normalizeNumber(formValues.month),
        yearly: !!formValues.yearly,
        sendInvoice: !!formValues.sendInvoice,
        hasVariableAmount: !!formValues.hasVariableAmount,
        sponsorTypeId: normalizeSelect(formValues.sponsorType),
        receiverId: normalizeSelect(formValues.receiver)
    };
}

export default function AgreementForm({ sponsor, agreement, receivers, sponsorTypes, save }) {
    const classes = useStyles();

    return (
        <div>
            <h1><Link to={`/sponsors/${sponsor?.id}`}> {sponsor?.name}</Link></h1>
            <Formik
                initialValues={toFormValues(agreement)}
                validationSchema={AgreementSchema}
                onSubmit={(values, { setSubmitting }) => {
                    const n = normalize(agreement, values);
                    save(n).then(() => setSubmitting(false));
                }}
            >
                {({ errors, touched, handleSubmit, isSubmitting }) => (
                    <Card className={classes.card}>
                        <CardContent>
                            <Form onSubmit={handleSubmit} className={classes.form}>
                                <Field
                                    component={DatePicker}
                                    format="yyyy-MM-dd"
                                    variant="filled"
                                    name="from"
                                    label="From"
                                    className={classes.formControl}
                                />

                                <Field
                                    component={DatePicker}
                                    format="yyyy-MM-dd"
                                    variant="filled"
                                    name="to"
                                    label="To"
                                    className={classes.formControl}
                                />

                                <TextInput errors={errors} touched={touched} name="amount" label="Amount" />

                                <Field component={CheckboxWithLabel} type="checkbox" name="yearly" Label={{ label: 'Yearly' }} />
                                <TextInput errors={errors} touched={touched} name="month" label="Month" />
                                <Field
                                    component={CheckboxWithLabel}
                                    type="checkbox"
                                    name="hasVariableAmount"
                                    Label={{ label: 'Variable Amount' }}
                                />

                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="sponsor-type">Sponsor Type</InputLabel>
                                    <Field
                                        component={Select}
                                        as="select"
                                        name="sponsorType"
                                        variant="filled"
                                        inputProps={{
                                            id: 'sponsor-type'
                                        }}
                                    >
                                        <MenuItem hidden disabled selected value="none">
                                            Select Sponsor Type
                                        </MenuItem>
                                        {sponsorTypes.map(st => (
                                            <MenuItem key={st.id} value={st.id}>
                                                {st.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </FormControl>

                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="receiver">Receiver</InputLabel>
                                    <Field
                                        component={Select}
                                        as="select"
                                        name="receiver"
                                        variant="filled"
                                        inputProps={{
                                            id: 'receiver'
                                        }}
                                    >
                                        <MenuItem value="none">Select Receiver</MenuItem>
                                        {receivers.map(st => (
                                            <MenuItem key={st.id} value={st.id}>
                                                {st.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </FormControl>

                                <Field component={CheckboxWithLabel} type="checkbox" name="sendInvoice" Label={{ label: 'Send Invoice' }} />
                            </Form>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                                Submit
                            </Button>
                        </CardActions>
                    </Card>
                )}
            </Formik>
        </div>
    );
}
