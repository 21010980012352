import parse from 'date-fns/parse';
import * as Papa from 'papaparse';
import { BankTransaction } from '../../../models/BankTransaction';

export default function parseFile(input: string) {
    const config = {
        delimiter: '', // auto-detect
        newline: '', // auto-detect
        quoteChar: '"',
        escapeChar: '"',
        header: true,
        dynamicTyping: false,
        encoding: '',
    };

    const start = input.indexOf('"Kontonummer"');
    input = input.substring(start);

    const result = Papa.parse(input, config);

    const settlements = result.data.map(d => convertLine(d));

    return settlements;
}

function convertLine(line: any): BankTransaction {
    // "Kontonummer";"Kontonavn";"Valutasort";"Bokf¯rt dato";"Forklarende tekst";
    // "Status"; "Transaksjonstype"; "Rentedato"; "Ut"; "Inn"; "Arkivref."; "Referanse"
    const transaction: BankTransaction = {
        accountNumber: line.Kontonummer,
        date: parse(line['Bokført dato'], 'dd.MM.yyyy', new Date()),
        text: line['Forklarende tekst'],
        status: line.Status,
        transaksjonsType: line.Transaksjonstype,
        out: strToNum(line.Ut),
        in: strToNum(line.Inn),
        arcRef: line.Arkivref,
    };

    return transaction;
}

function strToNum(input: string) {
    if (input != null) {
        const s = input.replace('.', '').replace(',', '.');
        const value = parseFloat(s);
        if (!isNaN(value)) {
            return value;
        }
    }

    return 0;
}
