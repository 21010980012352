import { TokenGetter } from '../hooks/TokenGetter';

const apiUrl = 'https://gca-server.herokuapp.com';

export function createUrl(path: string) {
    return `${apiUrl}/${path}`;
}

export async function getToken(tokenGetter: TokenGetter) {
    return await tokenGetter({
        audience: 'https://gambiacare.herokuapp.com/api',
        scope: 'read:sponsors',
    });
}

export async function getHeaders(tokenGetter: TokenGetter) {
    const token = await getToken(tokenGetter);

    return {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
}

export function handleErrors(response: Response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export async function fetchGet<T>(path: string, tokenGetter: TokenGetter) {
    const url = createUrl(path);
    const headers = await getHeaders(tokenGetter);

    const response = await fetch(url, { headers });
    handleErrors(response);

    const responseData = (await response.json()) as T;
    return responseData;
}

export async function fetchPost<T>(path: string, data: Object, tokenGetter: TokenGetter) {
    const url = createUrl(path);
    const headers = await getHeaders(tokenGetter);

    const response = await fetch(url, {
        method: 'post',
        headers,
        body: JSON.stringify(data),
    });
    handleErrors(response);

    const responseData = (await response.json()) as T;
    return responseData;
}

export async function fetchPut<T>(path: string, data: Object, tokenGetter: TokenGetter) {
    const url = createUrl(path);
    const headers = await getHeaders(tokenGetter);

    const response = await fetch(url, {
        method: 'put',
        headers,
        body: JSON.stringify(data),
    });
    handleErrors(response);

    const responseData = (await response.json()) as T;
    return responseData;
}

export async function fetchDelete(path: string, tokenGetter: TokenGetter) {
    const url = createUrl(path);
    const headers = await getHeaders(tokenGetter);

    const response = await fetch(url, {
        method: 'delete',
        headers,
    });
    handleErrors(response);
}
