import { Button } from '@material-ui/core';
import { saveAs } from 'file-saver';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { vippsTransactionsAtom } from '../../atoms/atoms';
import { createPostingsCsv } from '../bank-reconsiliation/helpers/createTripletexPostingCsv';
import convert from './helpers/vippsToPostings';

export default function VippsPostingsDownload() {
    const transactions = useRecoilValue(vippsTransactionsAtom);

    const download = () => {
        const postings = convert(transactions);
        const file = createPostingsCsv(postings);

        const blob = new Blob([file], { type: 'text/csv' });
        saveAs(blob, 'vipps-bilag.csv');
    };

    return (
        <div>
            <Button variant="contained" onClick={download}>
                Download
            </Button>
        </div>
    );
}
