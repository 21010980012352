import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import React from 'react';
import useCopyEmails from '../../hooks/useCopyEmails';
import { AgreementEx } from '../../models/Agreement';
import { Receiver } from '../../models/Receiver';
import { Sponsor } from '../../models/Sponsor';
import { SponsorType } from '../../models/SponsorType';
import { excelDownload, ExcelField, ExcelFields } from '../utils/excelDownload';

export type SponsorActionsProps = {
    sponsors: Sponsor[];
    agreements: AgreementEx[];
    sponsorTypes: SponsorType[];
    receivers: Receiver[];
};

type SponsorExcelData = {
    custNo: string;
    name: string;
    email1: string;
    email2: string;
    agreements: number;
    amountM: number;
    amountY: number;
};

type AgreementExcelData = {
    custNo: string;
    name: string;
    email1: string;
    email2: string;
    sponsorType?: string;
    receiver?: string;
    amountM?: number;
    amountY?: number;
};

export default function SponsorActions({ sponsors, agreements, sponsorTypes, receivers }: SponsorActionsProps) {
    const { copy } = useCopyEmails();

    function downloadSponsors() {
        const result = [];

        for (const sponsor of sponsors) {
            const sponsorAgreements = agreements.filter(a => a.sponsorId === sponsor.id && a.isActive);

            const a: SponsorExcelData = {
                custNo: sponsor.custNo,
                name: sponsor.name,
                email1: sponsor.email1,
                email2: sponsor.email2,
                agreements: sponsorAgreements.length,
                amountM: sponsorAgreements.filter(a => !a.yearly).reduce((p, c) => p + c.amount, 0),
                amountY: sponsorAgreements.filter(a => a.yearly).reduce((p, c) => p + c.amount, 0),
            };

            result.push(a);
        }

        const data: ExcelField<SponsorExcelData>[] = [
            { title: 'Navn', data: x => x.name },
            { title: 'Kundenummer', data: x => x.custNo },
            { title: 'E Post 1', data: x => x.email1 },
            { title: 'E Post 2', data: x => x.email2 },
            { title: 'Avtaler', data: x => x.agreements },
            { title: 'Beløp Månedlig', data: x => x.amountM },
            { title: 'Beløå Årlig', data: x => x.amountY },
        ];
        const fields: ExcelFields<SponsorExcelData> = { data };

        excelDownload(fields, result, 'Faddere', true);
    }

    function downloadAgreements() {
        const result: AgreementExcelData[] = [];

        for (const sponsor of sponsors) {
            const sponsorAgreements = agreements.filter(a => a.sponsorId === sponsor.id && a.isActive);
            if (sponsorAgreements.length > 0) {
                for (const agreement of sponsorAgreements) {
                    const sponsorType = sponsorTypes.find(s => s.id === agreement.sponsorTypeId);
                    const receiver = receivers.find(r => r.id === agreement.receiverId);

                    const a: AgreementExcelData = {
                        custNo: sponsor.custNo,
                        name: sponsor.name,
                        email1: sponsor.email1,
                        email2: sponsor.email2,
                        sponsorType: sponsorType?.name,
                        receiver: receiver?.name,
                        amountM: agreement.yearly ? undefined : agreement.amount,
                        amountY: agreement.yearly ? agreement.amount : undefined,
                    };

                    result.push(a);
                }
            } else {
                const a = {
                    custNo: sponsor.custNo,
                    name: sponsor.name,
                    email1: sponsor.email1,
                    email2: sponsor.email2,
                    sponsorType: undefined,
                    receiver: undefined,
                    amountM: undefined,
                    amountY: undefined,
                };

                result.push(a);
            }
        }

        const data: ExcelField<AgreementExcelData>[] = [
            { title: 'Navn', data: x => x.name },
            { title: 'Kundenummer', data: x => x.custNo },
            { title: 'E Post 1', data: x => x.email1 },
            { title: 'E Post 2', data: x => x.email2 },
            { title: 'Type', data: x => x.sponsorType },
            { title: 'Mottaker', data: x => x.receiver },
            { title: 'Beløp Månedlig', data: x => x.amountM },
            { title: 'Beløå Årlig', data: x => x.amountY },
        ];
        const fields: ExcelFields<AgreementExcelData> = { data };

        excelDownload<AgreementExcelData>(fields, result, 'Fadder Avtaler', true);
    }

    function copyEmails() {
        copy(sponsors);
    }

    return (
        <Card>
            <CardActions>
                <Button variant="contained" onClick={downloadSponsors}>
                    Last ned faddere
                </Button>
                <Button variant="contained" onClick={downloadAgreements}>
                    Last ned avtaler
                </Button>
                <Button variant="contained" onClick={copyEmails}>
                    Kopier e-post adresser
                </Button>
            </CardActions>
        </Card>
    );
}
