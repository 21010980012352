import { mergeQueryStatuses } from '../features/utils/query-utils';
import useAgreement from '../hooks/useAgreement';
import useReceivers from '../hooks/useReceivers';
import useSponsor from '../hooks/useSponsor';
import useSponsorsTypes from '../hooks/useSponsorTypes';

function useEditAgreementContext(agreementId: string) {
    const agreement = useAgreement(agreementId, false);
    const sponsor = useSponsor(agreement?.data?.sponsorId);
    const receivers = useReceivers();
    const sponsorTypes = useSponsorsTypes();

    const merge = mergeQueryStatuses([agreement, sponsor, receivers, sponsorTypes]);

    const result = {
        sponsor: sponsor.data,
        agreement: agreement.data,
        receivers: receivers.data ?? [],
        sponsorTypes: sponsorTypes.data ?? [],
        isIdle: merge.isIdle,
        isLoading: merge.isLoading,
        isFetching: merge.isLoading,
        error: merge.error,
    };

    return result;
}

export default useEditAgreementContext;
