import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { sponsorsAll } from '../backend/sponsorsApi';

function useSponsors() {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    return useQuery(
        'sponsors',
        async () => {
            return await sponsorsAll(getAccessTokenSilently);
        },
        { enabled: isAuthenticated }
    );
}

export default useSponsors;
