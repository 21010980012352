import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';

const useStyles = makeStyles({
    field: {
        marginBottom: '20px',
    },
});
export default function TextInput({ name, label, errors, touched, multiline }) {
    const classes = useStyles();
    return (
        <Field
            component={TextField}
            error={touched[name] && !!errors[name]}
            helperText={touched[name] && errors[name]}
            variant="filled"
            name={name}
            type="text"
            label={label}
            className={classes.field}
            multiline={multiline}
            autoComplete="off"
        />
    );
}
