import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import { useRecoilState } from 'recoil';
import { taxFilterAtom } from '../../atoms/atoms';

export type TaxSettlementFilterProps = {
    count: number;
};

export default function TaxSettlementFilter({ count }: TaxSettlementFilterProps) {
    const [filter, setFilter] = useRecoilState(taxFilterAtom);

    function handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {
        const newFilter = { ...filter, text: event.target.value };
        setFilter(newFilter);
    }

    function clearTextFilter() {
        const newFilter = { ...filter, text: '' };
        setFilter(newFilter);
    }

    function handleWithMissingSosialSecurityNumberChange() {
        const newFilter = { ...filter, withMissingSosialSecurityNumber: !filter.withMissingSosialSecurityNumber };
        setFilter(newFilter);
    }

    function handleWithMissingEMail() {
        const newFilter = { ...filter, withMissingEmail: !filter.withMissingEmail };
        setFilter(newFilter);
    }

    function handleOnlyWillBeReported() {
        const newFilter = { ...filter, onlyWillBeReported: !filter.onlyWillBeReported };
        setFilter(newFilter);
    }

    function handleOnlyWithAmount() {
        const newFilter = { ...filter, onlyWithAmount: !filter.onlyWithAmount };
        setFilter(newFilter);
    }

    function handleLunchPartner() {
        const newFilter = { ...filter, lunchPartner: !filter.lunchPartner };
        setFilter(newFilter);
    }

    return (
        <Card>
            <CardActions>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                    <Grid item>
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="filter-text">Filter</InputLabel>
                            <OutlinedInput
                                id="filter-text"
                                type="text"
                                value={filter.text}
                                onChange={handleTextChange}
                                autoComplete="off"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton edge="end" onClick={clearTextFilter}>
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filter.withMissingSosialSecurityNumber}
                                    onChange={handleWithMissingSosialSecurityNumberChange}
                                    name="missingSosialSecurity"
                                    color="primary"
                                />
                            }
                            label="Mangler F.Nr"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filter.withMissingEmail}
                                    onChange={handleWithMissingEMail}
                                    name="missingEmail"
                                    color="primary"
                                />
                            }
                            label="Mangler Email"
                        />
                    </Grid>

                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filter.onlyWillBeReported}
                                    onChange={handleOnlyWillBeReported}
                                    name="willBeReportet"
                                    color="primary"
                                />
                            }
                            label="Vil bli rapportert"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filter.onlyWithAmount}
                                    onChange={handleOnlyWithAmount}
                                    name="withAmount"
                                    color="primary"
                                />
                            }
                            label="Har gitt gave (>=500)"
                        />
                    </Grid>

                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox checked={filter.lunchPartner} onChange={handleLunchPartner} name="lunchPartner" color="primary" />
                            }
                            label="Skole Fadder"
                        />
                    </Grid>

                    <Grid item>{count}</Grid>
                </Grid>
            </CardActions>
        </Card>
    );
}
