import * as Papa from 'papaparse';

export type BalanceLine = {
    id?: string;
    custNo?: string;
    amount: number;
};

export default function parseBalanceFile(input: string) {
    const config = {
        delimiter: '', // auto-detect
        newline: '', // auto-detect
        quoteChar: '"',
        escapeChar: '"',
        header: true,
        dynamicTyping: false,
        encoding: '',
    };

    const result = Papa.parse(input, config);

    const settlements = result.data.map(d => balanceLine(d));

    return settlements;
}

function balanceLine(line: any) {
    // Nummer;Navn;Organisasjonsnummer;Inngående saldo;Endring;Utgående saldo;Salg
    const transaction: BalanceLine = {
        custNo: line.Nummer,
        amount: strToNum(line.Salg),
    };

    return transaction;
}

function strToNum(input: string) {
    if (input != null) {
        const s = input.replace('.', '').replace(',', '.');
        const value = parseFloat(s);
        if (!isNaN(value)) {
            return value;
        }
    }

    return 0;
}
