import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import FileContent from './FileContent';
import FileImport from './FileImport';
import useBankReconsiliation from './helpers/useBankReconsiliation';
import TransactionsDownload from './TransactionsDownload';

export type TabPabelProps = {
    children: any;
    value: string | number;
    index: number;
};

function TabPanel(props: TabPabelProps) {
    const { children, value, index } = props;

    if (value !== index) {
        return null;
    }

    return (
        <div role="tabpanel" id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
            <Box p={3}>{children}</Box>
        </div>
    );
}

export default function BankReconsiliation() {
    const [activePage, setActivePage] = React.useState(0);
    const { transactions, bankAccounts, isLoading, error } = useBankReconsiliation();

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <Error error={error} />;
    }

    const handleChange = (event: React.ChangeEvent<object>, newValue: any) => {
        setActivePage(newValue);
    };

    return (
        <div>
            <AppBar position="static">
                <Tabs variant="scrollable" scrollButtons="auto" value={activePage} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="File Import" />
                    <Tab label="File Content" />
                    <Tab label="Unknown" />
                    {bankAccounts.map(a => (
                        <Tab key={a.id} label={a.name} value={a.id} />
                    ))}
                    <Tab label="Download" value="download" />
                </Tabs>
            </AppBar>

            <TabPanel index={activePage} value={0}>
                <FileImport />
            </TabPanel>

            <TabPanel index={activePage} value={1}>
                <FileContent transactions={transactions} showSponsor />
            </TabPanel>

            <TabPanel index={activePage} value={2}>
                <FileContent transactions={transactions.filter(t => t.sponsor == null)} />
            </TabPanel>

            <TabPanel index={activePage} value="download">
                <TransactionsDownload transactions={transactions} bankAccounts={bankAccounts} />
            </TabPanel>

            {bankAccounts.map(a => (
                <TabPanel key={a.id} index={activePage} value={a.id}>
                    <FileContent transactions={transactions.filter(t => t.bankAccount?.id === a.id)} />
                </TabPanel>
            ))}
        </div>
    );
}
