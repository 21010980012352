import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import CheckboxPropertyView from './CheckboxPropertyView';
import TextPropertyView from './TextPropertyView';

const useStyles = makeStyles({
    root: {
        minWidth: 250,
        maxWidth: 600
    },
    count: {
        width: 100
    },
    amount: {
        width: 150
    },
    form: {
        '& .MuiTextField-root': {
            margin: 10
        }
    }
});
export default function AgreementDetails({ agreement, sponsorTypes, receivers }) {
    const classes = useStyles();

    const sponsorType = sponsorTypes.find(st => st.id === agreement.sponsorTypeId);
    const receiver = receivers.find(r => r.id === agreement.receiverId);

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography variant="h5" component="h2">
                    {sponsorType.name} {!agreement.isActive && <span>(Not Active)</span>}
                </Typography>
            </CardContent>
            <CardContent>
                <form className={classes.form} noValidate autoComplete="off">
                    <Box display="flex" flexDirection="column">
                        <TextPropertyView label="Receiver" alwaysShow value={receiver?.name} />
                        <TextPropertyView label="From" alwaysShow value={agreement.from} />
                        <TextPropertyView label="To" alwaysShow value={agreement.to} />
                        <TextPropertyView label="Amount" value={agreement.amount} />
                        <CheckboxPropertyView label="Yearly" value={agreement.yearly} />
                        <CheckboxPropertyView label="Variable" value={agreement.hasVariableAmount} />
                        <CheckboxPropertyView label="Invoice" value={agreement.invoice} />
                    </Box>
                </form>
            </CardContent>
            <CardActions>
                <Button variant="contained" component={Link} to={`/agreements/edit/${agreement.id}`}>
                    Edit
                </Button>
            </CardActions>
        </Card>
    );
}
