import { DropzoneArea } from 'material-ui-dropzone';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { vippsTransactionsAtom } from '../../atoms/atoms';
import parseVippsFile from './helpers/vipps-file-parser';


export default function FileImport() {
    const setVippsTransactions = useSetRecoilState(vippsTransactionsAtom);

    function handleChange(files) {
        files.forEach(file => {
            const reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = () => {
                const content = parseVippsFile(reader.result);
                setVippsTransactions(content);
            };
            reader.readAsText(file, 'ISO-8859-1');
        });
    }
    return (
        <div>
            <DropzoneArea acceptedFiles={['text/csv']} filesLimit={1} onChange={handleChange} />
        </div>
    );
}
