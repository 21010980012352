import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { showDrawer } from '../../atoms/atoms';
import { items } from './MenuItems';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: '20px',
    },
    title: {
        flexGrow: 1,
    },
}));

function MenuItem({ item }) {
    return (
        <IconButton color="inherit" component={Link} key={item.link} to={item.link}>
            {item.icon}
        </IconButton>
    );
}

export default function Heading() {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useRecoilState(showDrawer);

    function handleDrawerClick() {
        setIsOpen(!isOpen);
    }

    return (
        <div className={classes.root}>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleDrawerClick}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        <Hidden smUp>GC</Hidden>
                        <Hidden xsDown>Gambia Care</Hidden>
                    </Typography>

                    {items
                        .filter(i => i.main)
                        .map(i => (
                            <MenuItem key={i.link} item={i}></MenuItem>
                        ))}
                </Toolbar>
            </AppBar>
        </div>
    );
}
