import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useParams } from 'react-router-dom';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import SponsorGrid from '../../components/SponsorGrid';
import useSponsorsContext from '../../dataContexts/useSponsorsContext';

const useStyles = makeStyles({
    root: {
        minWidth: 275
    },
    count: {
        width: 100
    },
    amount: {
        width: 150
    }
});

function createListItem(agreement, sponsors, receivers) {
    const sponsor = sponsors.find(s => s.id === agreement.sponsorId);
    const receiver = receivers.find(st => st.id === agreement.receiverId);

    if (sponsor == null) {
        return null;
    }

    return {
        id: agreement.id,
        sponsor: sponsor.name,
        sponsorId: sponsor.id,
        receiver: receiver?.name,
        receiverId: receiver?.id,
        monthly: !agreement.yearly ? agreement.amount : '',
        yearly: agreement.yearly ? agreement.amount : ''
    };
}

export default function SponsorTypeDetails() {
    let { id } = useParams();
    const classes = useStyles();
    let { sponsors, agreements, receivers, sponsorTypes, isLoading, error } = useSponsorsContext();

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <Error error={error} />;
    }

    const sponsorType = sponsorTypes.find(r => r.id === id);
    if (!sponsorType) {
        return <div>Not found</div>;
    }

    const listData = agreements
        .filter(a => a.sponsorTypeId === sponsorType.id && a.isActive)
        .map(a => createListItem(a, sponsors, receivers))
        .filter(a => a != null);

    listData.sort((a, b) => a.sponsor.localeCompare(b.sponsor));

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography variant="h5" component="h2">
                    {sponsorType.name}
                </Typography>
                <SponsorGrid sponsors={listData} />
            </CardContent>
        </Card>
    );
}
