import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AgreementEdit from '../agreement/AgreementEdit';
import AgreementNew from '../agreement/AgreementNew';
import BankReconsiliation from '../bank-reconsiliation/BankReconsiliation';
import Import from '../import/Import';
import ReceiverDetails from '../receivers/ReceiverDetails';
import Receivers from '../receivers/Receivers';
import SponsorDetails from '../sponsor-details/SponsorDetails';
import SponsorEdit from '../sponsor-edit/SponsorEdit.jsx';
import SponsorNew from '../sponsor-edit/SponsorNew';
import SponsorTypeDetails from '../sponsor-types/SponsorTypeDetails';
import SponsorTypes from '../sponsor-types/SponsorTypes';
import Sponsors from '../sponsors/Sponsors';
import TaxSettlementImport from '../tax-settlement/TaxSettlementImport';
import TaxSettlementList from '../tax-settlement/TaxSettlementList';
import VippsReconsiliation from '../vipps-reconsiliation/VippsReconsiliation';
import YearlyAgreements from '../yearly-agreements/YearlyAgreements';
import Home from './Home';
import Logout from './Logout';

const useStyles = makeStyles(() => ({
    container: {
        marginTop: '20px',
    },
}));

export default function Content() {
    const classes = useStyles();

    return (
        <main className={classes.content}>
            <Container maxWidth="lg" className={classes.container}>
                <Switch>
                    <Route exact path="/sponsors/edit/:id" children={<SponsorEdit />} />
                    <Route exact path="/sponsors/new" children={<SponsorNew />} />
                    <Route exact path="/sponsors/:id" children={<SponsorDetails />} />
                    <Route path="/sponsors" children={<Sponsors />} />
                    <Route exact path="/agreements/edit/:agreementId" children={<AgreementEdit />} />
                    <Route exact path="/agreements/new/:sponsorId" children={<AgreementNew />} />
                    <Route path="/sponsor-types/:id" children={<SponsorTypeDetails />} />
                    <Route path="/sponsor-types" children={<SponsorTypes />} />
                    <Route exact path="/receivers/:id" children={<ReceiverDetails />} />
                    <Route path="/receivers" children={<Receivers />} />
                    <Route path="/yearly" children={<YearlyAgreements />} />
                    <Route path="/bank-reconsiliation" children={<BankReconsiliation />} />
                    <Route path="/vipps-reconsiliation" children={<VippsReconsiliation />} />
                    <Route path="/tax-settlement/list" children={<TaxSettlementList />} />
                    <Route path="/tax-settlement/import" children={<TaxSettlementImport />} />
                    <Route path="/import" children={<Import />} />
                    <Route path="/logout" children={<Logout />} />
                    <Route exact path="/" children={<Home />} />
                </Switch>
            </Container>
        </main>
    );
}
