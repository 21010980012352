import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { receiversAll } from '../backend/receiversApi';

function useReceivers() {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    return useQuery(
        'receivers',
        async () => {
            return await receiversAll(getAccessTokenSilently);
        },
        { enabled: isAuthenticated }
    );
}

export default useReceivers;
