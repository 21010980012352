import { TokenGetter } from '../hooks/TokenGetter';
import { Receiver } from '../models/Receiver';
import { fetchGet, fetchPost } from './api';

export const receiversAll = async (tokenGetter: TokenGetter) => {
    return await fetchGet<Receiver[]>('receivers', tokenGetter);
};

export const receiverAdd = async (tokenGetter: TokenGetter, receiver: Receiver) => {
    return await fetchPost<Receiver>('receivers', receiver, tokenGetter);
};
