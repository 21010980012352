import * as Papa from 'papaparse';
import { BalanceLine } from './customer-balance-import';

export default function parseLossFile(input: string) {
    const config = {
        delimiter: '', // auto-detect
        newline: '', // auto-detect
        quoteChar: '"',
        escapeChar: '"',
        header: true,
        dynamicTyping: false,
        encoding: '',
    };

    const result = Papa.parse(input, config);

    const settlements = result.data.map(d => lossLine(d));

    return settlements;
}

function lossLine(line: any) {
    //Bilagsnummer;Bilagsår;Bilagsbeskrivelse;Dato;Kontonummer;Kontonavn;Kundenummer;Kundenavn;Leverandørnummer;Leverandørnavn;Ansattnummer;Ansattnavn;Avdelingsnummer;Avdeling
    const transaction: BalanceLine = {
        custNo: line.Kundenummer,
        amount: strToNum(line['Beløp']),
    };

    return transaction;
}

function strToNum(input: string) {
    if (input != null) {
        const s = input.replace('.', '').replace(',', '.');
        const value = parseFloat(s);
        if (!isNaN(value)) {
            return value;
        }
    }

    return 0;
}
