import { useAuth0 } from '@auth0/auth0-react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { queryCache, useMutation } from 'react-query';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sponsorDelete } from '../../backend/sponsorsApi';
import ConfirmDialog from '../../components/ConfirmDialog';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import PullRight from '../../components/PullRight';
import useSponsorsContext from '../../dataContexts/useSponsorsContext';
import AgreementDetails from './AgreementDetails';
import CheckboxPropertyView from './CheckboxPropertyView';
import MarkdownPropertyView from './MarkdownPropertyView';
import TextPropertyView from './TextPropertyView';

const useStyles = makeStyles({
    root: {
        minWidth: 205,
        maxWidth: 600,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiTextField-root': {
            margin: 10,
        },
    },
});

export default function SponsorDetails() {
    let { id } = useParams();
    const classes = useStyles();
    let { sponsors, agreements, receivers, sponsorTypes, isLoading, error } = useSponsorsContext();
    let [confirmOpen, setConfirmOpen] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    let history = useHistory();

    const [mutate] = useMutation(s => sponsorDelete(getAccessTokenSilently, s), { throwOnError: true });

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <Error error={error} />;
    }

    const sponsor = sponsors.find(r => r.id === id);
    if (!sponsor) {
        return <div>Not found</div>;
    }

    const deleteSponsorExec = async s => {
        try {
            await mutate(s);
            queryCache.invalidateQueries(['sponsors'], {
                refetchActive: true,
                refetchInactive: false,
            });
            toast.success(`"${s.name}" Deleted`);
            history.push(`/sponsors`);
        } catch (error) {
            toast.error(`Error deleting "${sponsor.name}"`, { autoClose: false });
        }
    };

    const deleteSponsor = () => {
        deleteSponsorExec(sponsor);
    };

    const listData = agreements.filter(a => a.sponsorId === sponsor.id);

    return (
        <>
            <Typography variant="h2" component="h1">
                {sponsor.name}
            </Typography>

            <Card className={classes.root}>
                <CardContent>
                    <Typography variant="h5" component="h2">
                        Details
                    </Typography>
                </CardContent>
                <CardContent>
                    <form className={classes.form} noValidate autoComplete="off">
                        <TextPropertyView label="name 1" value={sponsor.name1} />
                        <TextPropertyView label="name 2" value={sponsor.name2} />
                        <TextPropertyView label="Email 1" value={sponsor.email1} />
                        <TextPropertyView label="Email 2" value={sponsor.email2} />
                        <TextPropertyView label="Cust No" value={sponsor.custNo} />
                        <TextPropertyView label="Birth Number" value={sponsor.birthNumber} />
                        <CheckboxPropertyView label="Reminder" value={sponsor.reminder} />
                        <MarkdownPropertyView label="Note" value={sponsor.note} />
                        <CheckboxPropertyView label="Is Organization" value={sponsor.isOrganization} />
                        <TextPropertyView label="Organization Number" value={sponsor.organizationNumber} />
                    </form>
                </CardContent>
                <CardActions>
                    <Button variant="contained" component={Link} to={`/sponsors/edit/${sponsor.id}`}>
                        Edit
                    </Button>
                    <Button variant="contained" component={Link} to={`/agreements/new/${sponsor.id}`}>
                        New Agreement
                    </Button>
                    <PullRight />
                    <Button variant="contained" color="secondary" onClick={() => setConfirmOpen(true)}>
                        Delete
                    </Button>
                </CardActions>
            </Card>

            <Box marginTop="20px" display="grid" gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))" gridGap="20px">
                {listData.map(a => (
                    <AgreementDetails key={a.id} agreement={a} receivers={receivers} sponsorTypes={sponsorTypes} />
                ))}
            </Box>

            <ConfirmDialog title="Delete Sponsor?" open={confirmOpen} setOpen={setConfirmOpen} onConfirm={deleteSponsor}>
                Are you sure you want to delete {sponsor.name}?
            </ConfirmDialog>
        </>
    );
}
