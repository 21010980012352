import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { queryCache, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sponsorUpdate } from '../../backend/sponsorsApi';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import useSponsor from '../../hooks/useSponsor';
import useUrlQuery from '../../hooks/useUrlQuery';
import SponsorEditForm from './SponsorEditForm';

const save = async (sponsor, mutate) => {
    try {
        await mutate(sponsor);
        toast.success(`"${sponsor.name}" Saved`);
        queryCache.invalidateQueries(['sponsors'], {
            refetchActive: true,
            refetchInactive: false,
        });
    } catch (error) {
        toast.error(`Error saving "${sponsor.name}"`, { autoClose: false });
    }
};

export default function SponsorEdit() {
    const { getAccessTokenSilently } = useAuth0();
    let { id } = useParams();
    const { isFetching, error, data } = useSponsor(id, false);
    let urlQuery = useUrlQuery();

    const [mutate] = useMutation(formData => sponsorUpdate(getAccessTokenSilently, formData), { throwOnError: true });

    if (isFetching) {
        return <Loading />;
    }

    if (error) {
        return <Error error={error} />;
    }

    const showAddAgreement = urlQuery.get('showAddAgreement') === 'true';

    return (
        <div>
            <SponsorEditForm sponsor={data} save={sponsor => save(sponsor, mutate)} showAddAgreement={showAddAgreement} />
        </div>
    );
}
